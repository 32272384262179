import React, { useContext } from "react";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faShapes,
  faUtensils,
  faQuestion,
  faImage,
  faCopy,
  faTimes,
  faCogs,
  faCalendarAlt,
  faBreadSlice,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./Submenu";
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";

import { UserContext } from "contexts/UserContext";

const packageJson = require("../../../package.json");

type SidebarProps = {
  isOpen: boolean;
  toggle: () => void;
};

/**
 * based on:
 * https://github.com/sergioadonis/react-bootstrap-simple-sidebar
 */
const Sidebar = ({ isOpen, toggle }: SidebarProps) => {
  let location = useLocation();

  React.useEffect(() => {
    console.log("Sidebar.location", location.pathname);
  }, [location]);

  const { userData } = useContext(UserContext);
  console.log("userData", userData);

  return (
    <div
      className={classNames("sidebar", { "is-open": isOpen }, "d-print-none")}
    >
      <div className="sidebar-header">
        <Button
          variant="link"
          onClick={toggle}
          style={{ color: "#fff" }}
          className="mt-4"
        >
          <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
        </Button>
        <h3 className="uc-logo">
          <Link to={"/"}>uncook</Link>
          &nbsp;
          <small className="text-muted" style={{ fontSize: "11px" }}>
            v{packageJson?.version}
          </small>
        </h3>
      </div>

      {userData.roles !== undefined &&
      userData.roles.length > 0 &&
      userData.roles.includes("rating") ? (
        <RatingMenu />
      ) : (
        <FullMenu />
      )}
    </div>
  );
};

const RatingMenu = () => {
  let location = useLocation();

  return (
    <Nav className="flex-column pt-2">
      <Nav.Item className={location.pathname === "/" ? "active" : ""}>
        <Link to={"/"} className="nav-link">
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          Dashboard
        </Link>
      </Nav.Item>
      <SubMenu
        title="Menu"
        icon={faCalendarAlt}
        path="/menu/"
        items={[
          {
            title: "Weeks calendar",
            path: "/menu/calendar/list",
          },
        ]}
      />
    </Nav>
  );
};

const FullMenu = () => {
  let location = useLocation();

  return (
    <Nav className="flex-column pt-2">
      {/* <p className="ml-3">Heading</p> */}

      <Nav.Item className={location.pathname === "/" ? "active" : ""}>
        <Link to={"/"} className="nav-link">
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          Dashboard
        </Link>
      </Nav.Item>

      <SubMenu
        title="Menu"
        icon={faCalendarAlt}
        path="/menu/"
        items={[
          {
            title: "Weeks templates",
            path: "/menu/weeks/list",
          },
          {
            title: "Weeks calendar",
            path: "/menu/calendar/list",
          },
        ]}
      />

      <SubMenu
        title="Kitchen"
        icon={faBreadSlice}
        path="/kitchen/"
        items={[
          {
            title: "Next cooking",
            path: "/kitchen/next-cooking",
          },
        ]}
      />

      <SubMenu
        title="Meals"
        icon={faUtensils}
        path="/meals/"
        items={[
          {
            title: "List",
            path: "/meals/all/list",
          },
          {
            title: "Semifinished",
            path: "/meals/semifinished/list",
          },
        ]}
      />

      <SubMenu
        title="Ingredients"
        icon={faShapes}
        path="/ingredients/"
        items={[
          {
            title: "List",
            path: "/ingredients/all/list",
          },
          {
            title: "Groups",
            path: "/ingredients/groups/list",
          },
          {
            title: "Allergens",
            path: "/settings/allergens/list",
          },
        ]}
      />

      <SubMenu
        title="Stats"
        icon={faChartLine}
        path="/stats/meals/rate/"
        items={[
          {
            title: "Rating",
            path: "/stats/meals/rate/",
          },
          {
            title: "Configurator",
            path: "/stats/meals/configurator/",
          },
          {
            title: "Finances",
            path: "/stats/meals/finances/",
          },
        ]}
      />

      <SubMenu
        title="Settings"
        icon={faCogs}
        path="/settings/"
        items={[
          {
            title: "Meal types",
            path: "/settings/meals-types/list",
          },
          {
            title: "Meal plans",
            path: "/settings/meals-plans/list",
          },
          {
            title: "Meals per day",
            path: "/settings/meals-perday/list",
          },
          {
            title: "Package types",
            path: "/settings/packages-types/list",
          },
        ]}
      />

      {/* <Nav.Item>
          <Nav.Link href="/">
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            About
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link href="/">
            <FontAwesomeIcon icon={faImage} className="mr-2" />
            Portfolio
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link href="/">
            <FontAwesomeIcon icon={faQuestion} className="mr-2" />
            FAQ
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link href="/">
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            Contact
          </Nav.Link>
        </Nav.Item> */}
    </Nav>
  );
};

export default Sidebar;
