import { Route, Switch } from "react-router-dom";

import List from "./List";
import Add from "./Add";
import Edit from "./Edit";
import Rates from "./Rates";
import Changes from "./Changes";

const Index = () => {
  const section = "menu/calendar";

  return (
    <Switch>
      <Route exact path={`/${section}/list`} component={List} />
      <Route exact path={`/${section}/add`} component={Add} />
      <Route exact path={`/${section}/edit/:id`} component={Edit} />
      <Route exact path={`/${section}/rates/:id`} component={Rates} />
      <Route exact path={`/${section}/changes/:id`} component={Changes} />
    </Switch>
  );
};

export default Index;
