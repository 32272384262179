import React, { useState, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Table, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faStar,
  faUtensils,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

import useModal from "hooks/useModal";
import Modal from "components/elements/Modal";

// type daysType = string;

type mealsTypesType = {
  id: number;
  title: string;
};

type datesTypes = {
  day: string;
  date: string;
};

type mealsChangesType = {
  datetime_added: string;
  id: number;
  phone: number | string;
  name: string;
  original_meal: string;
  original_meal_type: string;
  replacement_meal: string;
  replacement_meal_type: string;
  rating_clients_id: number;
};

type mealsTypes = {
  id: number;
  title: string;
  changes_avg: number;
  changes_qty: number;
  meal_changes: Array<mealsChangesType>;
};

type mealTypesConnType = {
  [key: string]: mealsTypes;
};

type initialDataType = {
  id: number;
  date_from: string;
  date_to: string;
  week_title: string;
  // days_available: Array<daysType>;
  dates_available: Array<datesTypes>;
  meals_types_available: Array<mealsTypesType>;
  // meals_available: Array<mealsTypesType>;
  weeks_id: number;
  meals: mealTypesConnType;
};

/* type buttonType = {
  title: string;
  onClick: () => void;
}; */
type ModalType = {
  title: string;
  message: string;
  // button?: buttonType;
  Component?: JSX.Element;
};

const Changes = (props: any) => {
  console.log("Changes", props);
  const section = "changes";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { modalIsOpen, modalToggle } = useModal();
  const [modalData, setModalData] = useState<ModalType>({
    title: "",
    message: "",
  });

  const initialDataState = {
    id: 0,
    date_from: "",
    date_to: "",
    week_title: "",
    // days_available: [],
    dates_available: [],
    meals_types_available: [],
    // meals_available: [],
    meals: {},
    weeks_id: 0,
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, `menu-${section}`, id)
      .then((response: any) => {
        console.log("Changes.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/menu/calendar/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <>
              <h3>{initialData.week_title}</h3>
              <h5>
                {initialData.date_from} → {initialData.date_to}
              </h5>
              <Table className="uc-weeks-changes mt-3" striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    {initialData.dates_available.map((currentDay, dindex) => (
                      <th className="text-center" key={`th-day-${dindex}`}>
                        {`${currentDay.day} (${currentDay.date})`}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {initialData.meals_types_available.map(
                    (mealsType, mindex) => (
                      <tr key={`tr-meal-${mindex}`}>
                        <td className="text-nowrap vertical-middle font-weight-bold">
                          {mealsType.title}
                        </td>
                        {initialData.dates_available.map(
                          (currentDate, dindex) => {
                            const currentMeal =
                              initialData.meals[
                                mealsType.id + "-" + currentDate.day
                              ] !== undefined
                                ? initialData.meals[
                                    mealsType.id + "-" + currentDate.day
                                  ]
                                : ({} as mealsTypes);

                            /*
                            Compile changes
                          */
                            const MealChanges = (): JSX.Element => {
                              if (currentMeal.meal_changes.length === 0) {
                                return <div>No changes yet..</div>;
                              }

                              return (
                                <Table
                                  className="mt-3 text-smaller"
                                  striped
                                  bordered
                                  hover
                                  size="sm"
                                >
                                  <thead>
                                    <tr>
                                      <th style={{ width: "130px" }}>Client</th>
                                      <th style={{ width: "110px" }}>Date</th>
                                      <th
                                        style={{ width: "80px" }}
                                        className="text-center"
                                      >
                                        Nový сhod
                                      </th>
                                      <th>Jidlo</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {currentMeal.meal_changes.map(
                                      (currentChange, currentChangeIndex) => (
                                        <tr
                                          key={`tr-meal-change-${currentChangeIndex}`}
                                        >
                                          <td className="vertical-middle">
                                            {currentChange.name !== ""
                                              ? currentChange.name
                                              : currentChange.phone}
                                          </td>
                                          <td className="vertical-middle">
                                            {currentChange.datetime_added.substring(
                                              0,
                                              currentChange.datetime_added
                                                .length - 3
                                            )}
                                          </td>
                                          <td className="vertical-middle text-center">
                                            {
                                              currentChange.replacement_meal_type
                                            }
                                          </td>
                                          <td className="vertical-middle">
                                            {currentChange.original_meal} →
                                            <br />
                                            {currentChange.replacement_meal}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              );
                            };

                            return (
                              <td
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                                className={`text-smaller text-center vertical-middle${(() => {
                                  if (
                                    currentMeal.changes_qty !== undefined &&
                                    currentMeal.changes_qty > 0
                                  )
                                    return " alert-success";
                                  else return "";
                                })()}`}
                                key={`td-day-${dindex}`}
                                onClick={(e) => {
                                  if (
                                    (e.target as HTMLTextAreaElement).matches(
                                      ".meal-item-link, .meal-item-link *"
                                    )
                                  ) {
                                    return false;
                                  }

                                  setModalData({
                                    title:
                                      currentDate.day +
                                      " (" +
                                      currentDate.date +
                                      "), " +
                                      mealsType.title,
                                    message: "",
                                    Component: <MealChanges />,
                                  });
                                  modalToggle();
                                }}
                              >
                                {currentMeal.title ? currentMeal.title : ""}
                                {currentMeal.changes_qty !== undefined &&
                                currentMeal.changes_qty > 0 ? (
                                  <div style={{ marginTop: "10px" }}>
                                    <FontAwesomeIcon
                                      style={{
                                        opacity: "0.5",
                                        marginRight: "5px",
                                      }}
                                      icon={faExchangeAlt}
                                    />
                                    &nbsp;
                                    {currentMeal.changes_qty}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {userData.roles !== undefined &&
                                userData.roles.length > 0 &&
                                userData.roles.includes("rating") ? null : (
                                  <Link
                                    to={`/meals/all/edit/${currentMeal.id}`}
                                    className="meal-item-link"
                                    style={{
                                      position: "absolute",
                                      top: "1px",
                                      right: "1px",
                                      backgroundColor: "rgba(255,255,255,0.5)",
                                      borderRadius: "10px",
                                      padding: "3px 7px",
                                    }}
                                    href="#"
                                  >
                                    <FontAwesomeIcon icon={faUtensils} />
                                  </Link>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>

      {modalData ? (
        <Modal
          title={modalData.title}
          message={modalData.message}
          Component={modalData.Component}
          isOpen={modalIsOpen}
          toggle={modalToggle}
          size="lg"
        />
      ) : null}
    </>
  );
};

export default Changes;
