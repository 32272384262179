import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Alert,
  Table,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import useModal from "hooks/useModal";
import Modal from "components/elements/Modal";

import RUG, { DropArea } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";

import { ListGroups, listGroupsTypes } from "./ListGroups";

type imageType = {
  uid?: string;
  file_name: string;
  source?: string;
};

const semifinishedSchema = yup.object().shape({
  // brutto: yup.number().moreThan(0).required(),
  netto: yup.number().moreThan(0).required(),
});

const ingredientsSchema = yup.object().shape({
  // brutto: yup.number().moreThan(0).required(),
  netto: yup.number().moreThan(0).required(),
});

const schema = yup.object({
  title: yup.string().required(),
  amount: yup.number().moreThan(0).required(),
  units_id: yup.number().moreThan(0),
  kcal: yup.number(),
  protein: yup.number(),
  fat: yup.number(),
  carbs: yup.number(),
  recipe: yup.string(),
  description: yup.string(),
  semifinished: yup.array().of(semifinishedSchema),
  ingredients: yup.array().of(ingredientsSchema),
  complexity_cooking: yup.number(),
  complexity_packaging: yup.number(),
});

type unitsTypes = {
  id: number;
  title: string;
};

type semifinishedTypes = {
  id: number;
  title: string;
  amount: number;
  units_name: string;
  loss_percent: number;
  brutto: number;
  netto: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  units_id: number;
  ks_qty: number;
  group_id: number;
};

type ingredientsTypes = {
  id: number;
  title: string;
  units_name: string;
  loss_percent: number;
  brutto: number;
  netto: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  units_id: number;
  ks_qty: number;
  group_id: number;
};

type initialDataType = {
  id: number;
  title: string;
  amount?: number | string;
  units_id: number;
  kcal?: number | string;
  protein?: number | string;
  fat?: number | string;
  carbs?: number | string;
  recipe?: string;
  description?: string;
  units: Array<unitsTypes>;
  semifinished: Array<semifinishedTypes>;
  semifinished_available: Array<semifinishedTypes>;
  semifinished_selector: string;
  ingredients: Array<ingredientsTypes>;
  ingredients_available: Array<ingredientsTypes>;
  ingredients_selector: string;
  images: Array<imageType>;
  images_kitchen: Array<imageType>;
  images_website: Array<imageType>;
  groups: Array<listGroupsTypes>;
  groups_available: Array<listGroupsTypes>;
  should_heat: string;
  complexity_cooking: number;
  complexity_packaging: number;
};

type TotalNumbersType = {
  brutto: number;
  netto: number;
  kcal: number | string;
  protein: number | string;
  fat: number | string;
  carbs: number | string;
};

type totalGroupsType = {
  netto: number;
};

const Edit = (props: any) => {
  console.log("Edit", props);
  const section = "all";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showKsQty, setShowKsQty] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [total, setTotal] = useState<TotalNumbersType>({
    brutto: 0,
    netto: 0,
    kcal: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });
  const [totalIngredients, setTotalIngredients] = useState<TotalNumbersType>({
    brutto: 0,
    netto: 0,
    kcal: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });
  const [totalSemifinished, setTotalSemifinished] = useState<TotalNumbersType>({
    brutto: 0,
    netto: 0,
    kcal: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });

  type buttonType = {
    title: string;
    onClick: () => void;
  };
  type ModalType = {
    title: string;
    message: string;
    button?: buttonType;
  };
  const { modalIsOpen, modalToggle } = useModal();
  const [modalData, setModalData] = useState<ModalType>({
    title: "",
    message: "",
  });

  const initialDataState = {
    id: 0,
    title: "",
    amount: "",
    units_id: 0,
    recipe: "",
    description: "",
    units: [],
    semifinished: [],
    semifinished_available: [],
    semifinished_selector: "",
    ingredients: [],
    ingredients_available: [],
    ingredients_selector: "",
    images: [],
    images_kitchen: [],
    images_website: [],
    groups: [],
    groups_available: [],
    should_heat: "0",
    complexity_cooking: 0,
    complexity_packaging: 0,
  };

  const [initialData, setInitialData] =
    useState<initialDataType>(initialDataState);

  const [totalGroups, setTotalGroups] = useState<totalGroupsType>({
    netto: 0,
  });

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, `meals-${section}`, id)
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });

          setTotal({
            brutto: response.data.brutto,
            netto: response.data.netto,
            kcal: response.data.kcal,
            protein: response.data.protein,
            fat: response.data.fat,
            carbs: response.data.carbs,
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  // Calculate ingredients data after load
  React.useEffect(() => {
    // console.log("useEffect", initialData);
    if (initialData.ingredients && initialData.ingredients.length > 0) {
      recalcTotalNumbers(
        initialData.semifinished,
        initialData.ingredients,
        true
      );
    }
  }, [initialData]);

  const recalcTotalNumbers = (
    newSemifinished: Array<semifinishedTypes>,
    newIngredients: Array<ingredientsTypes>,
    isInit?: boolean
  ) => {
    // console.log("recalcTotalNumbers", newSemifinished, newIngredients);

    if (typeof isInit === "undefined") isInit = false;

    let newSemifinishedTotals = {
      brutto: 0,
      netto: 0,
      kcal: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
    };

    newSemifinished.map((item, index) => {
      let itemBrutto = parseFloat(item.brutto as any);
      let itemNetto = parseFloat(item.netto as any);
      newSemifinishedTotals.brutto += isNaN(itemBrutto)
        ? 0
        : itemBrutto < 0
        ? 0
        : itemBrutto;
      newSemifinishedTotals.netto += isNaN(itemNetto)
        ? 0
        : itemNetto < 0
        ? 0
        : itemNetto;

      const foundIndex = initialData.semifinished_available.findIndex(
        (x) => x.id == item.id
      );
      const originalSemifinished =
        initialData.semifinished_available[foundIndex];

      const itemPercent = (item.netto * 100) / originalSemifinished.netto;
      /* newSemifinishedTotals.kcal +=
        originalSemifinished.kcal * (itemPercent / 100); */
      newSemifinishedTotals.kcal +=
        itemNetto * (originalSemifinished.kcal / originalSemifinished.amount);
      newSemifinishedTotals.protein +=
        originalSemifinished.protein * (itemPercent / 100);
      newSemifinishedTotals.fat +=
        originalSemifinished.fat * (itemPercent / 100);
      newSemifinishedTotals.carbs +=
        originalSemifinished.carbs * (itemPercent / 100);
    });

    newSemifinishedTotals.brutto =
      Math.round(newSemifinishedTotals.brutto * 100) / 100;
    newSemifinishedTotals.netto =
      Math.round(newSemifinishedTotals.netto * 100) / 100;
    newSemifinishedTotals.kcal =
      Math.round(newSemifinishedTotals.kcal * 100) / 100;
    newSemifinishedTotals.protein =
      Math.round(newSemifinishedTotals.protein * 100) / 100;
    newSemifinishedTotals.fat =
      Math.round(newSemifinishedTotals.fat * 100) / 100;
    newSemifinishedTotals.carbs =
      Math.round(newSemifinishedTotals.carbs * 100) / 100;
    setTotalSemifinished(newSemifinishedTotals);

    let newIngredientsTotals = {
      brutto: 0,
      netto: 0,
      kcal: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
    };

    newIngredients.map((item, index) => {
      let itemBrutto = parseFloat(item.brutto as any);
      let itemNetto = parseFloat(item.netto as any);

      newIngredientsTotals.brutto += isNaN(itemBrutto)
        ? 0
        : itemBrutto < 0
        ? 0
        : itemBrutto;
      newIngredientsTotals.netto += isNaN(itemNetto)
        ? 0
        : itemNetto < 0
        ? 0
        : itemNetto;

      const foundIndex = initialData.ingredients_available.findIndex(
        (x) => x.id == item.id
      );
      const originalIngredient = initialData.ingredients_available[foundIndex];

      const itemPercent = (item.netto * 100) / originalIngredient.netto;
      /* newIngredientsTotals.kcal +=
        originalIngredient.kcal * (itemPercent / 100); */
      newIngredientsTotals.kcal +=
        itemNetto * (originalIngredient.kcal / originalIngredient.brutto);
      newIngredientsTotals.protein +=
        originalIngredient.protein * (itemPercent / 100);
      newIngredientsTotals.fat += originalIngredient.fat * (itemPercent / 100);
      newIngredientsTotals.carbs +=
        originalIngredient.carbs * (itemPercent / 100);
    });
    newIngredientsTotals.brutto =
      Math.round(newIngredientsTotals.brutto * 100) / 100;
    newIngredientsTotals.netto =
      Math.round(newIngredientsTotals.netto * 100) / 100;
    newIngredientsTotals.kcal =
      Math.round(newIngredientsTotals.kcal * 100) / 100;
    newIngredientsTotals.protein =
      Math.round(newIngredientsTotals.protein * 100) / 100;
    newIngredientsTotals.fat = Math.round(newIngredientsTotals.fat * 100) / 100;
    newIngredientsTotals.carbs =
      Math.round(newIngredientsTotals.carbs * 100) / 100;
    setTotalIngredients(newIngredientsTotals);

    /*
      Summary
    */

    let newTotals = {
      brutto: 0,
      netto: 0,
      kcal: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
    };

    newTotals.brutto =
      Math.round(
        (newSemifinishedTotals.brutto + newIngredientsTotals.brutto) * 100
      ) / 100;
    newTotals.netto =
      Math.round(
        (newSemifinishedTotals.netto + newIngredientsTotals.netto) * 100
      ) / 100;
    newTotals.kcal =
      Math.round(
        (newSemifinishedTotals.kcal + newIngredientsTotals.kcal) * 100
      ) / 100;
    newTotals.protein =
      Math.round(
        (newSemifinishedTotals.protein + newIngredientsTotals.protein) * 100
      ) / 100;
    newTotals.fat =
      Math.round((newSemifinishedTotals.fat + newIngredientsTotals.fat) * 100) /
      100;
    newTotals.carbs =
      Math.round(
        (newSemifinishedTotals.carbs + newIngredientsTotals.carbs) * 100
      ) / 100;

    if (!isInit) setTotal(newTotals);
  };

  return (
    <>
      <Row>
        <Col>
          <h3>
            {/* <Link to={`/meals/${section}/list`}> */}
            <a
              href="#"
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </a>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xl="10">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                /*
                  Additional validation
                */

                // Save last images
                formData.images = initialData.images;
                formData.images_kitchen = initialData.images_kitchen;
                formData.images_website = initialData.images_website;

                // Save total numbers
                // formData.brutto = total.brutto;
                // formData.netto = total.netto;
                formData.kcal = total.kcal;
                formData.protein = total.protein;
                formData.carbs = total.carbs;
                formData.fat = total.fat;

                console.log("onSubmit", formData, initialData);

                if (
                  formData.ingredients.length === 0 &&
                  formData.semifinished.length === 0
                ) {
                  setErrorMessage("No semifinished or ingredients are added");
                  setSubmitting(false);
                  return;
                }

                Api.edit(
                  userData.session_hash,
                  "meals-" + section,
                  formData.id,
                  formData
                ).then(
                  (response) => {
                    console.log("Add.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                      setSubmitting(false);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      history.push(`/meals/${section}/list`);
                    }
                  },
                  (error) => {
                    console.log("Edit.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/*console.log("errors", errors)*/}
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          name="title"
                          isInvalid={!!errors.title}
                        />
                      </Form.Group>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Unit</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.units_id}
                            onChange={(e) => {
                              if (e.target.value === "1") {
                                if (!showKsQty) setShowKsQty(true);
                              } else {
                                if (showKsQty) setShowKsQty(false);
                              }
                              handleChange(e);
                            }}
                            name="units_id"
                            isInvalid={!!errors.units_id}
                          >
                            <option value="0">--</option>
                            {initialData.units.map((type) => (
                              <option value={type.id} key={`unit-${type.id}`}>
                                {type.title}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.amount}
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }
                              handleChange(e);
                            }}
                            name="amount"
                            isInvalid={!!errors.amount}
                            autoComplete="off"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Brutto</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.brutto}
                            readOnly={true}
                            name="total-brutto"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Netto</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.netto}
                            readOnly={true}
                            name="total-netto"
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>kCal</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.kcal}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...total };
                              newTotals.kcal = e.target.value;
                              setTotal(newTotals);

                              handleChange(e);
                            }}
                            name="kcal"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Bilkoviny</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.protein}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...total };
                              newTotals.protein = e.target.value;
                              setTotal(newTotals);

                              handleChange(e);
                            }}
                            name="protein"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Sacharidy</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.carbs}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...total };
                              newTotals.carbs = e.target.value;
                              setTotal(newTotals);

                              handleChange(e);
                            }}
                            name="carbs"
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>Tuky</Form.Label>
                          <Form.Control
                            type="text"
                            value={total.fat}
                            /* readOnly={true} */
                            onChange={(e) => {
                              if (e.target.value.includes(",")) {
                                e.target.value = e.target.value.replace(
                                  /,/,
                                  "."
                                );
                              }

                              let newTotals = { ...total };
                              newTotals.fat = e.target.value;
                              setTotal(newTotals);

                              handleChange(e);
                            }}
                            name="fat"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Сложность, готовка</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.complexity_cooking}
                            name="complexity_cooking"
                            isInvalid={!!errors.complexity_cooking}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="0">Просто</option>
                            <option value="1">Средне</option>
                            <option value="2">Сложно</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Сложность, упаковка</Form.Label>
                          <Form.Control
                            as="select"
                            custom
                            value={values.complexity_packaging}
                            name="complexity_packaging"
                            isInvalid={!!errors.complexity_packaging}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="0">Просто</option>
                            <option value="1">Средне</option>
                            <option value="2">Сложно</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}></Form.Group>
                        <Form.Group as={Col}></Form.Group>
                      </Form.Row>
                    </Col>
                  </Row>

                  {/*
                      Ingredients
                    */}

                  <Form.Row className="my-3">
                    <Form.Group as={Col}>
                      <div className="uc-hrdivider mt-4">
                        <hr />
                        <span>Ingredients</span>
                      </div>
                      <Table striped bordered hover className="table-middle">
                        <thead>
                          <tr>
                            <th>Ingredient</th>
                            <th>Unit</th>
                            <th>%</th>
                            <th>Brutto</th>
                            <th>Netto</th>
                            <th>kCal</th>
                            <th>Group</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.ingredients.map((item, index) => (
                            <tr key={`table-row-${item.id}`}>
                              <td>{item.title}</td>
                              <td>{item.units_name}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.loss_percent}
                                  onChange={(e) => {
                                    let loss_percent = e.target.value;
                                    if (loss_percent.includes(",")) {
                                      loss_percent = loss_percent.replace(
                                        /,/,
                                        "."
                                      );
                                    }

                                    let newIngredients = values.ingredients;
                                    const foundIndex = newIngredients.findIndex(
                                      (x) => x.id == item.id
                                    );
                                    newIngredients[foundIndex].loss_percent =
                                      loss_percent as any;

                                    /*
                                      Calc brutto
                                    */

                                    let brutto =
                                      Math.round(
                                        ((item.netto * 100) /
                                          (100 - parseFloat(loss_percent))) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(brutto)) brutto = 0;
                                    newIngredients[foundIndex].brutto =
                                      brutto as any;

                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    recalcTotalNumbers(
                                      values.semifinished,
                                      newIngredients
                                    );
                                  }}
                                  name={`ingredient-${item.id}-loss_percent`}
                                  /* isInvalid={!!errors[`ingredient-${item.id}-brutto`} */
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Row>
                                  {item.units_id === 1 ? (
                                    <>
                                      <Col>
                                        <InputGroup className="mb-2 mr-sm-2">
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              ks:
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <Form.Control
                                            type="text"
                                            style={{ maxWidth: "80px" }}
                                            value={item.ks_qty}
                                            onChange={(e) => {
                                              let ks_qty = e.target.value;
                                              if (ks_qty.includes(",")) {
                                                ks_qty = ks_qty.replace(
                                                  /,/,
                                                  "."
                                                );
                                              }

                                              let newIngredients =
                                                values.ingredients;
                                              const foundIndex =
                                                newIngredients.findIndex(
                                                  (x) => x.id == item.id
                                                );
                                              newIngredients[
                                                foundIndex
                                              ].ks_qty = ks_qty as any;

                                              const foundAvailableIndex =
                                                values.ingredients_available.findIndex(
                                                  (x) => x.id == item.id
                                                );

                                              /*
                                                Calc netto
                                              */

                                              let netto =
                                                Math.round(
                                                  values.ingredients_available[
                                                    foundAvailableIndex
                                                  ].netto *
                                                    parseFloat(ks_qty) *
                                                    100
                                                ) / 100;

                                              if (Number.isNaN(netto))
                                                netto = 0;
                                              newIngredients[foundIndex].netto =
                                                netto as any;

                                              /*
                                                kCal
                                              */

                                              let kcal =
                                                Math.round(
                                                  (netto as any) *
                                                    (values
                                                      .ingredients_available[
                                                      foundAvailableIndex
                                                    ].kcal /
                                                      values
                                                        .ingredients_available[
                                                        foundAvailableIndex
                                                      ].brutto) *
                                                    100
                                                ) / 100;
                                              newIngredients[foundIndex].kcal =
                                                kcal as any;

                                              /*
                                                Calc brutto
                                              */

                                              let brutto =
                                                Math.round(
                                                  values.ingredients_available[
                                                    foundAvailableIndex
                                                  ].brutto *
                                                    parseFloat(ks_qty) *
                                                    100
                                                ) / 100;

                                              if (Number.isNaN(brutto))
                                                brutto = 0;
                                              newIngredients[
                                                foundIndex
                                              ].brutto = brutto as any;

                                              console.log(
                                                "setFieldValue",
                                                newIngredients,
                                                values.ingredients,
                                                values.ingredients_available
                                              );

                                              setFieldValue(
                                                "ingredients",
                                                newIngredients
                                              );

                                              recalcTotalNumbers(
                                                values.semifinished,
                                                newIngredients
                                              );

                                              console.log(
                                                "setFieldValue",
                                                values.ingredients,
                                                values.ingredients_available
                                              );
                                            }}
                                            name={`ingredient-${item.id}-ks_qty`}
                                            isInvalid={
                                              errors.ingredients &&
                                              errors.ingredients instanceof
                                                Array &&
                                              errors.ingredients.length >=
                                                index + 1 &&
                                              errors.ingredients[index] !==
                                                undefined &&
                                              "ks_qty" in
                                                (errors.ingredients[
                                                  index
                                                ] as any)
                                                ? true
                                                : false
                                            }
                                            autoComplete="off"
                                          />
                                        </InputGroup>
                                      </Col>
                                    </>
                                  ) : null}
                                  <Col>
                                    <Form.Control
                                      type="text"
                                      style={{ maxWidth: "80px" }}
                                      value={item.brutto}
                                      readOnly={true}
                                      onChange={(e) => {
                                        let brutto = e.target.value;
                                        if (brutto.includes(",")) {
                                          brutto = brutto.replace(/,/, ".");
                                        }

                                        let newIngredients = values.ingredients;
                                        const foundIndex =
                                          newIngredients.findIndex(
                                            (x) => x.id == item.id
                                          );
                                        newIngredients[foundIndex].brutto =
                                          brutto as any;

                                        /*
                                      Calc netto
                                    */

                                        let netto =
                                          Math.round(
                                            parseFloat(brutto) *
                                              ((100 - item.loss_percent) /
                                                100) *
                                              100
                                          ) / 100;

                                        if (Number.isNaN(netto)) netto = 0;
                                        newIngredients[foundIndex].netto =
                                          netto as any;

                                        setFieldValue(
                                          "ingredients",
                                          newIngredients
                                        );

                                        recalcTotalNumbers(
                                          values.semifinished,
                                          newIngredients
                                        );
                                      }}
                                      name={`ingredient-${item.id}-brutto`}
                                      isInvalid={
                                        errors.ingredients &&
                                        errors.ingredients instanceof Array &&
                                        errors.ingredients.length >=
                                          index + 1 &&
                                        errors.ingredients[index] !==
                                          undefined &&
                                        "brutto" in
                                          (errors.ingredients[index] as any)
                                          ? true
                                          : false
                                      }
                                      autoComplete="off"
                                    />
                                  </Col>
                                </Form.Row>
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.netto}
                                  onChange={(e) => {
                                    let netto = e.target.value;
                                    if (netto.includes(",")) {
                                      netto = netto.replace(/,/, ".");
                                    }

                                    let newIngredients = values.ingredients;
                                    const foundIndex = newIngredients.findIndex(
                                      (x) => x.id == item.id
                                    );
                                    newIngredients[foundIndex].netto =
                                      netto as any;

                                    /*
                                      kCal
                                    */

                                    let availableIngredients =
                                      values.ingredients_available;
                                    const foundAvailableIndex =
                                      availableIngredients.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    let kcal =
                                      Math.round(
                                        (netto as any) *
                                          (availableIngredients[
                                            foundAvailableIndex
                                          ].kcal /
                                            availableIngredients[
                                              foundAvailableIndex
                                            ].brutto) *
                                          100
                                      ) / 100;
                                    newIngredients[foundIndex].kcal =
                                      kcal as any;

                                    /*
                                      Calc brutto
                                    */

                                    let brutto =
                                      Math.round(
                                        ((parseFloat(netto) * 100) /
                                          (100 - item.loss_percent)) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(brutto)) brutto = 0;
                                    newIngredients[foundIndex].brutto =
                                      brutto as any;

                                    console.log(
                                      "setFieldValue",
                                      newIngredients,
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    recalcTotalNumbers(
                                      values.semifinished,
                                      newIngredients
                                    );

                                    console.log(
                                      "setFieldValue",
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                  }}
                                  name={`ingredient-${item.id}-netto`}
                                  isInvalid={
                                    errors.ingredients &&
                                    errors.ingredients instanceof Array &&
                                    errors.ingredients.length >= index + 1 &&
                                    errors.ingredients[index] !== undefined &&
                                    "netto" in
                                      (errors.ingredients[index] as any)
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.kcal}
                                  readOnly={true}
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  as="select"
                                  custom
                                  name={`ingredient-${item.id}-groupid`}
                                  value={item.group_id}
                                  onChange={(e) => {
                                    const newGroupId = e.target.value;
                                    console.log(
                                      "Ingredient group change",
                                      item,
                                      newGroupId
                                    );
                                    // handleChange(e);

                                    let newIngredients = values.ingredients;
                                    const foundIndex = newIngredients.findIndex(
                                      (x) => x.id == item.id
                                    );
                                    newIngredients[foundIndex].group_id =
                                      newGroupId as any;

                                    recalcTotalNumbers(
                                      values.semifinished,
                                      newIngredients
                                    );
                                  }}
                                >
                                  <option value="0">--</option>
                                  {values.groups.map((currentGroup) => {
                                    if (currentGroup.title === "") return null;
                                    return (
                                      <option
                                        key={`ingredient-group-${item.id}-${currentGroup.id}`}
                                        value={currentGroup.id}
                                      >
                                        {currentGroup.title}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              </td>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    setModalData({
                                      title: "Confirmation",
                                      message:
                                        "Do you really want to remove this ingredient?",
                                      button: {
                                        title: "Yes",
                                        onClick: () => {
                                          console.log("yes");

                                          const newIngredients =
                                            values.ingredients.filter(function (
                                              obj
                                            ) {
                                              return obj.id !== item.id;
                                            });

                                          setFieldValue(
                                            "ingredients",
                                            newIngredients
                                          );

                                          recalcTotalNumbers(
                                            values.semifinished,
                                            newIngredients
                                          );
                                        },
                                      },
                                    });
                                    modalToggle();
                                  }}
                                  className={`inline-action-item text-danger`}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th colSpan={3}></th>
                            <th>{totalIngredients.brutto}</th>
                            <th>{totalIngredients.netto}</th>
                            <th>{totalIngredients.kcal}</th>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <Typeahead
                                id="ingredient-selector"
                                multiple={false}
                                allowNew={false}
                                onChange={(selected: any) => {
                                  const selectedItem =
                                    selected.length > 0
                                      ? { ...selected[0] }
                                      : {};
                                  const value =
                                    selected.length > 0
                                      ? selectedItem.title
                                      : "";

                                  /*
                                    Add new ingredient to the list
                                  */
                                  if (value != "") {
                                    selectedItem.brutto = 0;
                                    selectedItem.netto = 0;
                                    selectedItem.ks_qty = 0;
                                    selectedItem.kcal = 0;
                                    let newIngredients = [
                                      ...values.ingredients,
                                      selectedItem,
                                    ];

                                    // Remove duplicated
                                    newIngredients = newIngredients.filter(
                                      (thing, index, self) =>
                                        index ===
                                        self.findIndex((t) => t.id === thing.id)
                                    );

                                    console.log(
                                      "ingredient-selector.onChange",
                                      newIngredients,
                                      values.ingredients,
                                      values.ingredients_available
                                    );
                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    setFieldValue("ingredients_selector", "");
                                  }
                                }}
                                onInputChange={(text: any, event: any) => {
                                  console.log(
                                    "ingredient-selector.onInputChange",
                                    text
                                  );
                                  setFieldValue("ingredients_selector", text);
                                }}
                                newSelectionPrefix="New ingredient: "
                                /* onBlur={(e: any) => setFieldTouched("title", true)} */
                                labelKey="title"
                                options={values.ingredients_available}
                                placeholder="Type ingredient name to add.."
                                selected={[values.ingredients_selector]}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Form.Row>

                  {/*
                      Semifinished
                    */}

                  <Form.Row className="my-3">
                    <Form.Group as={Col}>
                      <div className="uc-hrdivider mt-4">
                        <hr />
                        <span>Semifinished</span>
                      </div>
                      <Table striped bordered hover className="table-middle">
                        <thead>
                          <tr>
                            <th>Semifinished</th>
                            <th>Unit</th>
                            <th>%</th>
                            <th>Brutto</th>
                            <th>Netto</th>
                            <th>kCal</th>
                            <th>Group</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.semifinished.map((item, index) => (
                            <tr key={`table-row-${item.id}`}>
                              <td>{item.title}</td>
                              <td>{item.units_name}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.loss_percent}
                                  onChange={(e) => {
                                    let loss_percent = e.target.value;
                                    if (loss_percent.includes(",")) {
                                      loss_percent = loss_percent.replace(
                                        /,/,
                                        "."
                                      );
                                    }

                                    let newSemifinished = values.semifinished;
                                    const foundIndex =
                                      newSemifinished.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    newSemifinished[foundIndex].loss_percent =
                                      loss_percent as any;

                                    /*
                                      Calc brutto
                                    */

                                    let brutto =
                                      Math.round(
                                        ((item.netto * 100) /
                                          (100 - parseFloat(loss_percent))) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(brutto)) brutto = 0;
                                    newSemifinished[foundIndex].brutto =
                                      brutto as any;

                                    setFieldValue(
                                      "semifinished",
                                      newSemifinished
                                    );

                                    recalcTotalNumbers(
                                      newSemifinished,
                                      values.ingredients
                                    );
                                  }}
                                  name={`semifinished-${item.id}-loss_percent`}
                                  /* isInvalid={!!errors[`semifinished-${item.id}-brutto`} */
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.brutto}
                                  readOnly={true}
                                  onChange={(e) => {
                                    let brutto = e.target.value;
                                    if (brutto.includes(",")) {
                                      brutto = brutto.replace(/,/, ".");
                                    }

                                    let newSemifinished = values.semifinished;
                                    const foundIndex =
                                      newSemifinished.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    newSemifinished[foundIndex].brutto =
                                      brutto as any;

                                    /*
                                      Calc netto
                                    */

                                    let netto =
                                      Math.round(
                                        parseFloat(brutto) *
                                          ((100 - item.loss_percent) / 100) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(netto)) netto = 0;
                                    newSemifinished[foundIndex].netto =
                                      netto as any;

                                    console.log(
                                      "setFieldValue",
                                      newSemifinished,
                                      values.semifinished,
                                      values.semifinished_available
                                    );
                                    setFieldValue(
                                      "semifinished",
                                      newSemifinished
                                    );

                                    recalcTotalNumbers(
                                      newSemifinished,
                                      values.ingredients
                                    );

                                    /* console.log(
                                      "setFieldValue",
                                      values.semifinished,
                                      values.semifinished_available
                                    ); */
                                  }}
                                  name={`semifinished-${item.id}-brutto`}
                                  isInvalid={
                                    errors.semifinished &&
                                    errors.semifinished instanceof Array &&
                                    errors.semifinished.length >= index + 1 &&
                                    errors.semifinished[index] !== undefined &&
                                    "brutto" in
                                      (errors.semifinished[index] as any)
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.netto}
                                  onChange={(e) => {
                                    let netto = e.target.value;
                                    if (netto.includes(",")) {
                                      netto = netto.replace(/,/, ".");
                                    }

                                    let newSemifinished = values.semifinished;
                                    const foundIndex =
                                      newSemifinished.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    newSemifinished[foundIndex].netto =
                                      netto as any;

                                    /*
                                      kCal
                                    */

                                    let availableSemifinished =
                                      values.semifinished_available;
                                    const foundAvailableIndex =
                                      availableSemifinished.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    let kcal =
                                      Math.round(
                                        (netto as any) *
                                          (availableSemifinished[
                                            foundAvailableIndex
                                          ].kcal /
                                            availableSemifinished[
                                              foundAvailableIndex
                                            ].amount) *
                                          100
                                      ) / 100;
                                    newSemifinished[foundIndex].kcal =
                                      kcal as any;

                                    /*
                                      Calc brutto
                                    */

                                    let brutto =
                                      Math.round(
                                        ((parseFloat(netto) * 100) /
                                          (100 - item.loss_percent)) *
                                          100
                                      ) / 100;

                                    if (Number.isNaN(brutto)) brutto = 0;
                                    newSemifinished[foundIndex].brutto =
                                      brutto as any;

                                    console.log(
                                      "setFieldValue",
                                      newSemifinished,
                                      values.semifinished,
                                      values.semifinished_available
                                    );
                                    setFieldValue(
                                      "semifinished",
                                      newSemifinished
                                    );

                                    recalcTotalNumbers(
                                      newSemifinished,
                                      values.ingredients
                                    );

                                    /* console.log(
                                      "setFieldValue",
                                      values.semifinished,
                                      values.semifinished_available
                                    ); */
                                  }}
                                  name={`semifinished-${item.id}-netto`}
                                  isInvalid={
                                    errors.semifinished &&
                                    errors.semifinished instanceof Array &&
                                    errors.semifinished.length >= index + 1 &&
                                    errors.semifinished[index] !== undefined &&
                                    "netto" in
                                      (errors.semifinished[index] as any)
                                      ? true
                                      : false
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  style={{ maxWidth: "80px" }}
                                  value={item.kcal}
                                  readOnly={true}
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  as="select"
                                  custom
                                  name={`semifinished-${item.id}-groupid`}
                                  value={item.group_id}
                                  onChange={(e) => {
                                    const newGroupId = e.target.value;
                                    console.log(
                                      "Semifinished group change",
                                      item,
                                      newGroupId
                                    );
                                    // handleChange(e);

                                    let newSemifinished = values.semifinished;
                                    const foundIndex =
                                      newSemifinished.findIndex(
                                        (x) => x.id == item.id
                                      );
                                    newSemifinished[foundIndex].group_id =
                                      newGroupId as any;

                                    recalcTotalNumbers(
                                      newSemifinished,
                                      values.ingredients
                                    );
                                  }}
                                >
                                  <option value="0">--</option>
                                  {values.groups.map((currentGroup) => {
                                    if (currentGroup.title === "") return null;
                                    return (
                                      <option
                                        key={`semifinished-group-${item.id}-${currentGroup.id}`}
                                        value={currentGroup.id}
                                      >
                                        {currentGroup.title}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              </td>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    setModalData({
                                      title: "Confirmation",
                                      message:
                                        "Do you really want to remove this semifinished?",
                                      button: {
                                        title: "Yes",
                                        onClick: () => {
                                          console.log("yes");

                                          const newSemifinished =
                                            values.semifinished.filter(
                                              function (obj) {
                                                return obj.id !== item.id;
                                              }
                                            );

                                          setFieldValue(
                                            "semifinished",
                                            newSemifinished
                                          );

                                          recalcTotalNumbers(
                                            newSemifinished,
                                            values.ingredients
                                          );
                                        },
                                      },
                                    });
                                    modalToggle();
                                  }}
                                  className={`inline-action-item text-danger`}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th colSpan={3}></th>
                            <th>{totalSemifinished.brutto}</th>
                            <th>{totalSemifinished.netto}</th>
                            <th>{totalSemifinished.kcal}</th>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              <Typeahead
                                id="semifinished-selector"
                                multiple={false}
                                allowNew={false}
                                onChange={(selected: any) => {
                                  const selectedItem =
                                    selected.length > 0
                                      ? { ...selected[0] }
                                      : {};
                                  const value =
                                    selected.length > 0
                                      ? selectedItem.title
                                      : "";

                                  /*
                                    Add new semifinished to the list
                                  */
                                  if (value != "") {
                                    selectedItem.brutto = 0;
                                    selectedItem.netto = 0;
                                    selectedItem.kcal = 0;
                                    let newSemifinished = [
                                      ...values.semifinished,
                                      selectedItem,
                                    ];

                                    // Remove duplicated
                                    newSemifinished = newSemifinished.filter(
                                      (thing, index, self) =>
                                        index ===
                                        self.findIndex((t) => t.id === thing.id)
                                    );

                                    console.log(
                                      "semifinished-selector.onChange",
                                      newSemifinished,
                                      values.semifinished,
                                      values.semifinished_available
                                    );
                                    setFieldValue(
                                      "semifinished",
                                      newSemifinished
                                    );

                                    setFieldValue("semifinished_selector", "");
                                  }
                                }}
                                onInputChange={(text: any, event: any) => {
                                  console.log(
                                    "semifinished-selector.onInputChange",
                                    text
                                  );
                                  setFieldValue("semifinished_selector", text);
                                }}
                                newSelectionPrefix="New semifinished: "
                                /* onBlur={(e: any) => setFieldTouched("title", true)} */
                                labelKey="title"
                                options={values.semifinished_available}
                                placeholder="Type semifinished name to add.."
                                selected={[values.semifinished_selector]}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Form.Row>

                  {/*
                      Groups
                  */}

                  <ListGroups
                    values={values}
                    setFieldValue={setFieldValue}
                    setModalData={setModalData}
                    modalToggle={modalToggle}
                    totalGroups={totalGroups}
                  />

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Recept</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={values.recipe}
                          name="recipe"
                          rows={6}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Popis pro klienty</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={values.description}
                          name="description"
                          rows={6}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          as="select"
                          custom
                          value={values.should_heat}
                          name="should_heat"
                          onChange={handleChange}
                          style={{ width: "auto" }}
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </Form.Control>
                        <Form.Label className="mb-0 ml-3">
                          Doporučujeme ohřát
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col
                      className="border border-0 rounded pt-3"
                      style={{ backgroundColor: "rgba(0, 123, 255, 0.1)" }}
                    >
                      <Form.Group>
                        <Form.Label>
                          <h6 className="text-primary">
                            Original (on a plate)
                          </h6>
                        </Form.Label>
                        <RUG
                          /* action="https://mgr.uncook.app/api/meals-all/image-upload/" // upload route */
                          customRequest={({
                            uid,
                            file,
                            send,
                            action,
                            headers,
                            onProgress,
                            onSuccess,
                            onError,
                          }: any) => {
                            setErrorMessage("");
                            const sessionHash = userData.session_hash;
                            const apiUrl = "meals-all/image-upload/";

                            const innerOnSuccess = (
                              uid: string,
                              response: any
                            ) => {
                              console.log("innerOnSuccess", uid, response);

                              setInitialData((oldData) => {
                                const newImagesList = [
                                  ...oldData.images,
                                  {
                                    uid: uid,
                                    file_name: response.data.file_name,
                                  },
                                ];

                                oldData.images = newImagesList;

                                console.log(
                                  "innerOnSuccess.setInitialData",
                                  oldData
                                );
                                return oldData;
                              });

                              onSuccess(uid, response);
                            };

                            return Api.imagesUpload({
                              uid,
                              file,
                              send,
                              apiUrl,
                              headers,
                              onProgress,
                              innerOnSuccess,
                              onError,
                              sessionHash,
                              setErrorMessage,
                            });
                          }}
                          source={(response: any) => response.data.url} // response image source
                          initialState={initialData.images}
                          header={({ openDialogue }: any) => (
                            <DropArea>
                              {(isDrag: any) => (
                                <Alert
                                  variant={isDrag ? "warning" : "secondary"}
                                  className="text-center"
                                >
                                  <div style={{ padding: 30 }}>
                                    <p>
                                      <b>Drag images here to add or..</b>
                                    </p>

                                    <div>
                                      <Button
                                        onClick={openDialogue}
                                        variant="primary"
                                      >
                                        Select images
                                      </Button>
                                    </div>
                                  </div>
                                </Alert>
                              )}
                            </DropArea>
                          )}
                          onWarning={(e: any, d: any) => {
                            console.log("onWarning", e, d);
                            // Invalid?
                            if (e === "accept") {
                              setErrorMessage("File type is not allowed");
                            }
                          }}
                          onDeleted={(image: any) => {
                            console.log("onDeleted", image);

                            // Remove image
                            setInitialData((oldData) => {
                              const imageFileName = oldData.images.find(
                                (x) => x && image.source.includes(x?.file_name)
                              );

                              if (imageFileName !== undefined) {
                                const sessionHash = userData.session_hash;
                                const apiUrl = `meals-all/image-delete/${imageFileName.file_name}/`;
                                Api.imagesDelete(sessionHash, apiUrl);
                              }

                              const newImagesList = oldData.images.filter(
                                (_current, i) => _current.uid !== image.uid
                              );

                              oldData.images = newImagesList;

                              console.log("onDeleted.setInitialData", oldData);
                              return oldData;
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col
                      className="border border-0 rounded pt-3"
                      style={{ backgroundColor: "rgba(255, 0, 0, 0.1)" }}
                    >
                      <Form.Group>
                        <Form.Label>
                          <h6 className="text-danger">
                            Configurator (boxes, no background)
                          </h6>
                        </Form.Label>
                        <RUG
                          /* action="https://mgr.uncook.app/api/meals-all/image-upload/" // upload route */
                          customRequest={({
                            uid,
                            file,
                            send,
                            action,
                            headers,
                            onProgress,
                            onSuccess,
                            onError,
                          }: any) => {
                            setErrorMessage("");
                            const sessionHash = userData.session_hash;
                            const apiUrl = "meals-all/image-upload/";

                            const innerOnSuccess = (
                              uid: string,
                              response: any
                            ) => {
                              console.log("innerOnSuccess", uid, response);

                              setInitialData((oldData) => {
                                const newImagesList = [
                                  ...oldData.images_website,
                                  {
                                    uid: uid,
                                    file_name: response.data.file_name,
                                  },
                                ];

                                oldData.images_website = newImagesList;

                                console.log(
                                  "innerOnSuccess.setInitialData",
                                  oldData
                                );
                                return oldData;
                              });

                              onSuccess(uid, response);
                            };

                            return Api.imagesUpload({
                              uid,
                              file,
                              send,
                              apiUrl,
                              headers,
                              onProgress,
                              innerOnSuccess,
                              onError,
                              sessionHash,
                              setErrorMessage,
                            });
                          }}
                          source={(response: any) => response.data.url} // response image source
                          initialState={initialData.images_website}
                          header={({ openDialogue }: any) => (
                            <DropArea>
                              {(isDrag: any) => (
                                <Alert
                                  variant={isDrag ? "warning" : "secondary"}
                                  className="text-center"
                                >
                                  <div style={{ padding: 30 }}>
                                    <p>
                                      <b>Drag images here to add or..</b>
                                    </p>

                                    <div>
                                      <Button
                                        onClick={openDialogue}
                                        variant="primary"
                                      >
                                        Select images
                                      </Button>
                                    </div>
                                  </div>
                                </Alert>
                              )}
                            </DropArea>
                          )}
                          onWarning={(e: any, d: any) => {
                            console.log("onWarning", e, d);
                            // Invalid?
                            if (e === "accept") {
                              setErrorMessage("File type is not allowed");
                            }
                          }}
                          onDeleted={(image: any) => {
                            console.log("onDeleted", image);

                            // Remove image
                            setInitialData((oldData) => {
                              const imageFileName = oldData.images_website.find(
                                (x) => x && image.source.includes(x?.file_name)
                              );

                              if (imageFileName !== undefined) {
                                const sessionHash = userData.session_hash;
                                const apiUrl = `meals-all/image-delete/${imageFileName.file_name}/`;
                                Api.imagesDelete(sessionHash, apiUrl);
                              }

                              const newImagesList =
                                oldData.images_website.filter(
                                  (_current, i) => _current.uid !== image.uid
                                );

                              oldData.images_website = newImagesList;

                              console.log("onDeleted.setInitialData", oldData);
                              return oldData;
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-4 mb-4">
                    <Col
                      className="border border-0 rounded pt-3"
                      style={{ backgroundColor: "rgba(40, 167, 69, 0.1)" }}
                    >
                      <Form.Group>
                        <Form.Label>
                          <h6 className="text-success">
                            Web (boxes with background)
                          </h6>
                        </Form.Label>
                        <RUG
                          /* action="https://mgr.uncook.app/api/meals-all/image-upload/" // upload route */
                          customRequest={({
                            uid,
                            file,
                            send,
                            action,
                            headers,
                            onProgress,
                            onSuccess,
                            onError,
                          }: any) => {
                            setErrorMessage("");
                            const sessionHash = userData.session_hash;
                            const apiUrl = "meals-all/image-upload/";

                            const innerOnSuccess = (
                              uid: string,
                              response: any
                            ) => {
                              console.log("innerOnSuccess", uid, response);
                              setInitialData((oldData) => {
                                const newImagesList = [
                                  ...oldData.images_kitchen,
                                  {
                                    uid: uid,
                                    file_name: response.data.file_name,
                                  },
                                ];

                                oldData.images_kitchen = newImagesList;

                                console.log(
                                  "innerOnSuccess.setInitialData",
                                  oldData
                                );
                                return oldData;
                              });

                              onSuccess(uid, response);
                            };

                            return Api.imagesUpload({
                              uid,
                              file,
                              send,
                              apiUrl,
                              headers,
                              onProgress,
                              innerOnSuccess,
                              onError,
                              sessionHash,
                              setErrorMessage,
                            });
                          }}
                          source={(response: any) => response.data.url} // response image source
                          initialState={initialData.images_kitchen}
                          header={({ openDialogue }: any) => (
                            <DropArea>
                              {(isDrag: any) => (
                                <Alert
                                  variant={isDrag ? "warning" : "secondary"}
                                  className="text-center"
                                >
                                  <div style={{ padding: 30 }}>
                                    <p>
                                      <b>Drag images here to add or..</b>
                                    </p>

                                    <div>
                                      <Button
                                        onClick={openDialogue}
                                        variant="primary"
                                      >
                                        Select images
                                      </Button>
                                    </div>
                                  </div>
                                </Alert>
                              )}
                            </DropArea>
                          )}
                          onWarning={(e: any, d: any) => {
                            console.log("onWarning", e, d);
                            // Invalid?
                            if (e === "accept") {
                              setErrorMessage("File type is not allowed");
                            }
                          }}
                          onDeleted={(image: any) => {
                            console.log("onDeleted", image);

                            // Remove image
                            setInitialData((oldData) => {
                              const imageFileName = oldData.images_kitchen.find(
                                (x) => x && image.source.includes(x?.file_name)
                              );

                              if (imageFileName !== undefined) {
                                const sessionHash = userData.session_hash;
                                const apiUrl = `meals-all/image-delete/${imageFileName.file_name}/`;
                                Api.imagesDelete(sessionHash, apiUrl);
                              }

                              const newImagesList =
                                oldData.images_kitchen.filter(
                                  (_current, i) => _current.uid !== image.uid
                                );

                              oldData.images_kitchen = newImagesList;

                              console.log("onDeleted.setInitialData", oldData);
                              return oldData;
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-5 pb-5">
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        className={isSubmitting ? "btn--loading" : ""}
                        onClick={() => {
                          setAddNew(false);
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? (
                          <div className="lds-dual-ring"></div>
                        ) : null}
                        Save
                      </Button>
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        className={isSubmitting ? "btn--loading ml-3" : " ml-3"}
                        onClick={() => {
                          setAddNew(true);
                          handleSubmit();
                        }}
                      >
                        {isSubmitting ? (
                          <div className="lds-dual-ring"></div>
                        ) : null}
                        Save & Add another
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>

      {modalData ? (
        <Modal
          title={modalData.title}
          message={modalData.message}
          button={modalData.button}
          isOpen={modalIsOpen}
          toggle={modalToggle}
        />
      ) : null}
    </>
  );
};

export default Edit;
