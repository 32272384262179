import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Alert, Form } from "react-bootstrap";
// import BarChart from "components/elements/BarChart";
import {
  LineChart,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "contexts/UserContext";
import Api from "services/api";

type entryType = {
  date?: string;
  value?: number;
};
type statsType = {
  percent?: Array<entryType>;
  avg?: Array<entryType>;
  clients?: Array<entryType>;
  meals?: Array<entryType>;
};
type dataType = {
  daily?: statsType;
  weekly?: statsType;
};

const Rates = () => {
  let history = useHistory();

  const { userData, authFailed } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<dataType>({});
  const [graphsWidth, setGraphsWidth] = useState<number>(700);

  const graphsWrapperRef = React.useRef<any>(null);

  // Load once on init
  useEffect(() => {
    retrieveData();
  }, []);

  // Width
  useLayoutEffect(() => {
    console.log(
      "width.useEffect",
      graphsWrapperRef,
      graphsWrapperRef?.current,
      graphsWrapperRef.current?.offsetWidth
    );
    if (
      graphsWrapperRef &&
      graphsWrapperRef !== null &&
      graphsWrapperRef.current &&
      graphsWrapperRef.current !== null &&
      "offsetWidth" in graphsWrapperRef.current &&
      graphsWrapperRef.current?.offsetWidth !== undefined &&
      graphsWrapperRef.current?.offsetWidth &&
      graphsWrapperRef.current?.offsetWidth !== null
    ) {
      let newWidth = graphsWrapperRef.current.offsetWidth - 50;
      if (newWidth > 1024) newWidth = 1024;
      console.log("width.setGraphsWidth", newWidth);
      setGraphsWidth(newWidth);
    }
  }, [graphsWrapperRef, graphsWrapperRef.current]);

  const retrieveData = async () => {
    const response = await Api.getAll(
      userData.session_hash,
      `stats/meals-rating/`
    );
    setIsLoading(false);

    console.log("retrieveData", response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      console.log("retrieveData.set", response.data);
      setErrorMessage("");
      setData(response.data);
    }
  };

  // Error?
  if (errorMessage !== "") {
    return (
      <Row className="list">
        <Col md="auto">
          <Alert variant="danger">{errorMessage}</Alert>
        </Col>
      </Row>
    );
  }

  /*
  const testdata = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  */

  return (
    <>
      {/* <Row>
        <Col className="mb-2">
          <h3>
            <Link to={`/settings/${section}/add`}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row> */}
      {isLoading ? (
        <Row className="list">
          <Col md="auto">
            <div className="lds-dual-ring"></div>
          </Col>
        </Row>
      ) : (
        <div ref={graphsWrapperRef}>
          <Row className="list">
            <Col md="auto">
              <div>
                <h5 className="mb-4">Daily qty of meals</h5>
                {data?.daily?.meals !== undefined ? (
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.daily.meals}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis
                      tickFormatter={(v: any) => {
                        return v;
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return <>{v}</>;
                      }}
                    />
                    {/* <Legend /> */}
                    <Line dataKey="value" /* stackId="a"*/ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>

              <div className="mt-5">
                <h5 className="mb-4">Daily qty of clients</h5>
                {data?.daily?.clients !== undefined ? (
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.daily.clients}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis
                      tickFormatter={(v: any) => {
                        return v;
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return <>{v}</>;
                      }}
                    />
                    {/* <Legend /> */}
                    <Line dataKey="value" /* stackId="a"*/ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>

              <div className="mt-5">
                <h5 className="mb-4">Daily percent of meals with rating</h5>
                {data?.daily?.percent !== undefined ? (
                  /* <BarChart data={data.daily.percent} /> */
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.daily.percent}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis
                      tickFormatter={(v: any) => {
                        return v + "%";
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return (
                          <>
                            {v}%<br />
                            customers: {t.payload.customers}
                            <br />
                            meals: {t.payload.meals}
                            <br />
                            rates: {t.payload.rates}
                          </>
                        );
                      }}
                    />
                    {/* <Legend /> */}
                    <Line dataKey="value" /* stackId="a"*/ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>

              <div className="mt-5">
                <h5 className="mb-4">Daily average meal rating</h5>
                {data?.daily?.avg !== undefined ? (
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.daily.avg}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis
                      type="number"
                      domain={[0, 5]}
                      ticks={[1, 2, 3, 4, 5]}
                      tickFormatter={(v: any) => {
                        return v;
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return (
                          <>
                            {v}
                            <br />
                            ratings total: {t.payload.count}
                          </>
                        );
                      }}
                    />
                    <Line dataKey="value" /* stackId="a" */ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>

              <div className="mt-5">
                <h5 className="mb-4">Weekly percent of meals with rating</h5>
                {data?.weekly?.percent !== undefined ? (
                  /* <BarChart data={data.daily.percent} /> */
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.weekly.percent}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tickFormatter={(v: any) => {
                        return "W" + v;
                      }}
                    />
                    <YAxis
                      tickFormatter={(v: any) => {
                        return v + "%";
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return (
                          <>
                            {v}%<br />
                            customers: {t.payload.customers}
                            <br />
                            meals: {t.payload.meals}
                            <br />
                            rates: {t.payload.rates}
                          </>
                        );
                      }}
                    />
                    {/* <Legend /> */}
                    <Line dataKey="value" /* stackId="a" */ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>

              <div className="mt-5">
                <h5 className="mb-4">Weekly average meal rating</h5>
                {data?.weekly?.avg !== undefined ? (
                  <LineChart
                    width={graphsWidth}
                    height={300}
                    data={data.weekly.avg}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      interval={0}
                      dataKey="date"
                      tickFormatter={(v: any) => {
                        return "W" + v;
                      }}
                    />
                    <YAxis
                      type="number"
                      domain={[0, 5]}
                      ticks={[1, 2, 3, 4, 5]}
                      tickFormatter={(v: any) => {
                        return v;
                      }}
                    />
                    <Tooltip
                      formatter={(v: any, i: any, t: any) => {
                        return (
                          <>
                            {v}
                            <br />
                            ratings total: {t.payload.count}
                          </>
                        );
                      }}
                    />
                    <Line dataKey="value" /* stackId="a" */ fill="#96C5F7" />
                  </LineChart>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

const CustomizedAxisTick = ({ x, y, stroke, payload }: any) => {
  // console.log("CustomizedAxisTick", x, y, stroke, payload);
  const date = new Date(payload.value);
  const short = date.getDate() + "/" + (date.getMonth() + 1);
  const weekDayNum = date.getDay();
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize="11px" x={0} y={5} fill="#666">
        <tspan textAnchor="middle" x="0" dy="5">
          {short}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="12">
          {weekDay[weekDayNum]}
        </tspan>
      </text>
    </g>
  );
};

export default Rates;
