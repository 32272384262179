import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import { Button } from "react-bootstrap";

/**
 * Design elements
 */
import SideBar from "./components/elements/Sidebar";

/**
 * Screens
 */
import Login from "./components/screens/Login";
import Dashboard from "./components/screens/Dashboard";
// Menu
import MenuWeeks from "./components/screens/menu/weeks/Index";
import MenuCalendar from "./components/screens/menu/calendar/Index";
// Kitchen
import KitchenNextCooking from "./components/screens/kitchen/NextCooking";
// Meals
import MealsAll from "./components/screens/meals/all/Index";
import MealsSemifinished from "./components/screens/meals/semifinished/Index";
// Ingredients
import IngredientsAll from "./components/screens/ingredients/all/Index";
import IngredientsGroups from "./components/screens/ingredients/groups/Index";
// Settings
import SettingsMealsTypes from "./components/screens/settings/meals-types/Index";
import SettingsMealsPlans from "./components/screens/settings/meals-plans/Index";
import SettingsMealsPerday from "./components/screens/settings/meals-perday/Index";
import SettingsPackagesTypes from "./components/screens/settings/packages-types/Index";
import SettingsAllergens from "./components/screens/settings/allergens/Index";
// Stats
import StatsMealsRate from "./components/screens/stats/meals/Rate";
import StatsMealsConfigurator from "./components/screens/stats/meals/Configurator";
import StatsMealsFinances from "./components/screens/stats/meals/Finances";

/**
 * Services
 */
import VersionControl from "./services/VersionControl";

import { UserContext } from "./contexts/UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import "./App.css";

const App = () => {
  const { userData, isAuthorized, logOut } = React.useContext(UserContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  if (!isAuthorized) {
    return <Login />;
  }

  const toggle = () => {
    setIsOpen((oldState) => !oldState);
  };

  return (
    <BrowserRouter>
      <div className="App wrapper">
        <SideBar toggle={toggle} isOpen={isOpen} />

        <div className="content container-fluid">
          <VersionControl />

          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <Button variant="outline-light" size="sm" onClick={toggle}>
              <FontAwesomeIcon icon={faAlignLeft} />
            </Button>
            {/*<Link to={"/"} className="navbar-brand">
              UnCook
            </Link> */}
            <div className="navbar-nav mr-auto">
              {/*<li className="nav-item">
                <Link to={"/"} className="nav-link">
                  Dashboard
                </Link>
  </li> */}

              {/* isAdmin && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )*/}

              {/* currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          ) */}
            </div>

            {userData?.id ? (
              <div className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <Link to={"/profile"} className="nav-link">
                    Profile
                  </Link>
            </li> */}
                <li className="nav-item">
                  <Button variant="outline-light" size="sm" onClick={logOut}>
                    LogOut
                  </Button>
                </li>
              </div>
            ) : (
              {
                /*<div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>
          </div>*/
              }
            )}
          </nav>

          <div className="container-fluid mt-3">
            <Switch>
              <Route exact path={"/"} component={Dashboard} />

              {/* Menu, weeks templates */}
              <Route path={"/menu/weeks"} component={MenuWeeks} />

              {/* Menu, weeks calendar */}
              <Route path={"/menu/calendar"} component={MenuCalendar} />

              {/* Kitchen, next cooking */}
              <Route
                path={"/kitchen/next-cooking"}
                component={KitchenNextCooking}
              />

              {/* Meals */}
              <Route path={"/meals/all"} component={MealsAll} />
              <Route
                path={"/meals/semifinished"}
                component={MealsSemifinished}
              />

              {/* Ingredients */}
              <Route path={"/ingredients/all"} component={IngredientsAll} />
              <Route
                path={"/ingredients/groups"}
                component={IngredientsGroups}
              />

              {/* Settings */}
              <Route
                path={"/settings/meals-types"}
                component={SettingsMealsTypes}
              />
              <Route
                path={"/settings/meals-plans"}
                component={SettingsMealsPlans}
              />
              <Route
                path={"/settings/meals-perday"}
                component={SettingsMealsPerday}
              />
              <Route
                path={"/settings/packages-types"}
                component={SettingsPackagesTypes}
              />
              <Route
                path={"/settings/allergens"}
                component={SettingsAllergens}
              />

              {/* Stats */}
              <Route path={"/stats/meals/rate"} component={StatsMealsRate} />
              <Route
                path={"/stats/meals/configurator"}
                component={StatsMealsConfigurator}
              />
              <Route
                path={"/stats/meals/finances"}
                component={StatsMealsFinances}
              />

              {/* <Route exact path={["/", "/home"]} component={Home} /> */}
              {/* <Route exact path="/login" component={Login} /> */}
              {/* <Route exact path="/profile" component={Profile} />
          <Route path="/user" component={BoardUser} />
        <Route path="/admin" component={BoardAdmin} /> */}
            </Switch>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
