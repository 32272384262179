import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const schema = yup.object({
  title: yup.string().required(),
  brutto: yup.number().moreThan(0).required(),
  units_id: yup.number().moreThan(0),
  loss_percent: yup.number().min(0).max(99.99).required(),
  kcal: yup.number().min(0).required(),
  protein: yup.number().min(0).required(),
  fat: yup.number().min(0).required(),
  carbs: yup
    .number()
    /* .transform((_, value) => {
      console.log("transform", value);
      if (value.includes(",")) {
        console.log(+value.replace(/,/, "."));
        return +value.replace(/,/, ".");
      }
      return value;
    }) */
    .min(0)
    .required(),
  ks_qty: yup.number().when("units_id", {
    is: 1,
    then: yup.number().moreThan(0).required(),
    otherwise: (schema) => schema,
  }),
  group_name: yup.string(),
  // allergens: yup.string(),
});

type unitsTypes = {
  id: number;
  title: string;
};

type groupsTypes = {
  id: number;
  title: string;
};

type allergensTypes = {
  id: number;
  title: string;
};

type initialDataType = {
  id: number;
  title: string;
  brutto?: number | string;
  units_id: number;
  ks_qty?: number | string;
  loss_percent?: number | string;
  kcal?: number | string;
  protein?: number | string;
  fat?: number | string;
  carbs?: number | string;
  units: Array<unitsTypes>;
  groups?: Array<groupsTypes>;
  group_name: string;
  allergens?: Array<allergensTypes>;
  allergens_names: Array<string>;
};

const Add = () => {
  const section = "all";
  let typeaheadRef: any = null;

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showKsQty, setShowKsQty] = useState(false);
  const [addNew, setAddNew] = useState(false);

  // const [singleSelections, setSingleSelections] = useState([]);

  const initialDataState = {
    id: 0,
    title: "",
    brutto: "",
    units_id: 0,
    ks_qty: "",
    loss_percent: "",
    kcal: "",
    protein: "",
    fat: "",
    carbs: "",
    units: [],
    groups: [],
    group_name: "",
    allergens: [],
    allergens_names: [],
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = () => {
    Api.getAll(userData.session_hash, "include/ingredients/")
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  /* const saveData = (event: any) => {
    const form = event.currentTarget;
    console.log("saveData", event);

    /* if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return false;
    }

    setValidated(true);
    
    validated={validated} */

  /*
      Validation
    * /

    if (currentData.title === "") {
      setErrors({ title: "" });
    }

    if (Object.keys(errors).length > 0) {
      event.preventDefault();
      event.stopPropagation();
      // setValidated(true);
      return false;
    }

    setErrorMessage("");
    setIsSubmitting(true);

    Api.add(userData.session_hash, "ingredients-" + section, currentData).then(
      (response) => {
        console.log("Add.response", response);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          history.push(`/ingredients/${section}/list`);
        }

        setIsSubmitting(false);
      },
      (error) => {
        console.log("Add.error", error);

        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setErrorMessage(resMessage);
        setIsSubmitting(false);
      }
    );

    event.preventDefault();
    event.stopPropagation();
    return false;
  };
  */

  /* const handleChange = (e: any) => {
    let fieldName = e.target.name;
    let fieldVal = e.target.value;
    console.log(
      "handleChange",
      fieldName,
      fieldVal,
      Number(currentData.ks_qty),
      Number(currentData.units_id)
    );

    // Is unit and is ks?
    if (fieldName === "units_id") {
      if (fieldVal === "1") {
        if (!showKsQty) setShowKsQty(true);
      } else {
        if (showKsQty) setShowKsQty(true);
      }
    }

    setInitialData((oldData) => {
      const newData = {
        ...oldData,
        [fieldName]: fieldVal,
      };
      console.log("handleChange", newData);

      return newData;
    });
  }; */

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/ingredients/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="8" lg="5" xl="4">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                Api.add(
                  userData.session_hash,
                  "ingredients-" + section,
                  formData
                ).then(
                  (response) => {
                    console.log("Add.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                      setSubmitting(false);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      if (addNew) {
                        history.push("/empty");
                        history.replace(`/ingredients/${section}/add`);
                      } else history.push(`/ingredients/${section}/list`);
                    }
                  },
                  (error) => {
                    console.log("Add.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={values.title}
                      onChange={handleChange}
                      name="title"
                      isInvalid={!!errors.title}
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Unit</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        value={values.units_id}
                        onChange={(e) => {
                          if (e.target.value === "1") {
                            if (!showKsQty) setShowKsQty(true);
                          } else {
                            if (showKsQty) setShowKsQty(false);
                          }
                          handleChange(e);
                        }}
                        name="units_id"
                        isInvalid={!!errors.units_id}
                      >
                        <option value="0">--</option>
                        {initialData.units.map((type) => (
                          <option value={type.id} key={`unit-${type.id}`}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    {showKsQty && (
                      <Form.Group as={Col}>
                        <Form.Label>Množství (kus)</Form.Label>
                        <Form.Control
                          type="text"
                          value={values.ks_qty}
                          onChange={(e) => {
                            if (e.target.value.includes(",")) {
                              e.target.value = e.target.value.replace(/,/, ".");
                            }
                            handleChange(e);
                          }}
                          name="ks_qty"
                          isInvalid={!!errors.ks_qty}
                          autoComplete="off"
                        />
                      </Form.Group>
                    )}

                    <Form.Group as={Col}>
                      <Form.Label>
                        {showKsQty ? "Brutto, gr." : "Brutto"}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={values.brutto}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="brutto"
                        isInvalid={!!errors.brutto}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Ztrata, %</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.loss_percent}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="loss_percent"
                        isInvalid={!!errors.loss_percent}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>kCal</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.kcal}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="kcal"
                        isInvalid={!!errors.kcal}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Bilkoviny</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.protein}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="protein"
                        isInvalid={!!errors.protein}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Sacharidy</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.carbs}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="carbs"
                        isInvalid={!!errors.carbs}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Tuky</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.fat}
                        onChange={(e) => {
                          if (e.target.value.includes(",")) {
                            e.target.value = e.target.value.replace(/,/, ".");
                          }
                          handleChange(e);
                        }}
                        name="fat"
                        isInvalid={!!errors.fat}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Group</Form.Label>
                      <Typeahead
                        id="group-selector"
                        multiple={false}
                        allowNew={true}
                        onChange={(selected: any) => {
                          console.log("selected", selected);
                          const value =
                            selected.length > 0 ? selected[0].title : "";
                          console.log("Typeahead.onChange", value);
                          setFieldValue("group_name", value);
                        }}
                        onInputChange={(text: any, event: any) => {
                          console.log("Typeahead.onInputChange", text);
                          setFieldValue("group_name", text);
                        }}
                        newSelectionPrefix="New group: "
                        /* onBlur={(e: any) => setFieldTouched("title", true)} */
                        labelKey="title"
                        options={values.groups}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Allergens</Form.Label>
                      <Typeahead
                        ref={(typeahead: any) => (typeaheadRef = typeahead)}
                        id="allergens-selector"
                        multiple={true}
                        allowNew={true}
                        selected={values.allergens_names}
                        onChange={(selected: any) => {
                          const value =
                            selected.length > 0 ? selected[0].title : "";
                          console.log("allergens.onChange", value, selected);
                          setFieldValue("allergens_names", selected);
                        }}
                        onInputChange={(text: any, event: any) => {
                          console.log("allergens.onInputChange", text);
                          // setFieldValue("allergens_names", text);
                        }}
                        newSelectionPrefix="New allergen: "
                        /* onBlur={(e: any) => setFieldTouched("title", true)} */
                        labelKey="title"
                        options={values.allergens}
                        onBlur={(e: any) => {
                          console.log("allergens.onBlur", e);
                          // setFieldTouched("title", true);

                          if (e.target.value !== "") {
                            // typeaheadRef.clear();
                            // console.log("typeaheadRef", typeaheadRef);
                            // console.log("typeaheadRef.selected", typeaheadRef);

                            let newSet = [
                              ...values.allergens_names,
                              { id: 0, title: e.target.value },
                            ];

                            newSet = newSet.filter(
                              (thing: any, index, self) =>
                                index ===
                                self.findIndex(
                                  (t: any) => t.title === thing.title
                                )
                            );

                            console.log(
                              "allergens.onBlur.set",
                              values.allergens_names,
                              newSet
                            );

                            typeaheadRef.clear();
                            setFieldValue("allergens_names", newSet);

                            /* // setFieldValue("allergens", selected);
                            setFieldValue("allergens_names", [
                              ...typeaheadRef.state.selected,
                              ...[{ id: 777, title: e.target.value }],
                            ]); */
                          }
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading" : ""}
                    onClick={() => {
                      setAddNew(false);
                      handleSubmit();
                    }}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Save
                  </Button>
                  <Button
                    type="button"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading ml-3" : " ml-3"}
                    onClick={() => {
                      setAddNew(true);
                      handleSubmit();
                    }}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Save & Add another
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Add;
