import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Alert, Table, Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

import useModal from "hooks/useModal";
import Modal from "components/elements/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faPrint,
  faCheckCircle,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { czWeekDay } from "utils/dates";
import {
  xlsxGenerate,
  xlsxGenerateStyled,
  SheetsType,
  SheetsDetailedType,
  CellStyles,
  utilsStyleRange,
} from "utils/xlsx";

import { samolepkyXLSX } from "components/documents/samolepky";
import { prehledXLSX } from "components/documents/prehled";
import { techCardsXLSX } from "components/documents/techCards";
import { techCardsAddendumXLSX } from "components/documents/techCardsAddendum";

type ModalType = {
  title: string;
  message: string;
  Component?: JSX.Element;
};

type ContactsType = {
  name: string;
  phones: Array<string>;
  emails: Array<string>;
};

type IngredientsType = {
  id: number;
  title: string;
  brutto: number;
  netto: number;
  qty: number;
  group_title?: string;
};

type CustomersType = {
  id: number;
  contacts: ContactsType;
  meals_plans_title: string;
  kitchen_info?: string;
  delka_tydne?: string;
  meals_types_names?: string;
  meals_types_replaced?: MealReplacedType;
  meals_types_program?: string;
  meals_chody_protein?: number;
};

type CookDaysType = {
  cook_day: string;
  eat_days: Array<string>;
};

type ClientsDataType = {
  sync_datetime: string;
  leads_syncs_id: number;
  cook_day: string;
  cook_days: Array<CookDaysType>;
  eat_days: Array<string>;
  customers: Array<CustomersType>;
  customers_count: number;
};

type CustomersTableType = {
  tableRef: any;
  customers: Array<CustomersType>;
};

type MealReplacedType = {
  [key: string]: string;
};

type MealInfoType = {
  [key: string]: MealType;
};

type MealDateInfoType = {
  meals: MealInfoType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_netto: number;
  meals_qty: number;
};

type GroupType = {
  netto: number;
  netto_auto: number;
  title: string;
};

type MealDatesType = {
  [key: string]: MealDateInfoType;
};

type MealType = {
  clients: Array<CustomersType>;
  ingredients: Array<IngredientsType>;
  semifinished: Array<SemifinishedType>;
  title: string;
  qty: number;
  netto: number;
  meals_types_title: string;
  meals_types_id: number;
  groups: Array<GroupType>;
};

type SemifinishedType = {
  id: number;
  title: string;
  netto: number;
  qty: number;
};

type MealsTypesType = {
  id: number;
  order_pos: number;
  title: string;
};

type ClientsAllType = {
  day: string;
  id: number;
  meals: Array<MealType>;
  name: string;
};

type MealsDataType = {
  cooking_id: number;
  meals: MealDatesType;
  meals_bigsmall: MealDatesType;
  semifinished: Array<SemifinishedType>;
  ingredients: Array<IngredientsType>;
  meals_qty: number;
  meals_types: Array<MealsTypesType>;
  clients: Array<ClientsAllType>;
  customers: Array<CustomersType>;
};

type MealsTableType = {
  tableRef: any;
  mealsDays: MealDatesType;
  setModalData: any;
  modalToggle: any;
};

const CustomersTable = ({ tableRef, customers }: CustomersTableType) => {
  // console.log("CustomersTable", customers);

  return (
    <Table striped bordered ref={tableRef}>
      <thead>
        <tr>
          <th className="d-print-none">Amo Id</th>
          <th>Name</th>
          <th>Program</th>
          <th className="text-nowrap">Meal types</th>
          <th className="text-nowrap">Bez sobot</th>
          <th className="text-nowrap">Neděle</th>
          <th className="text-nowrap">Protein</th>
          <th>Info pro kuchyň</th>
        </tr>
      </thead>
      <tbody>
        {customers.map((item, index: number) => (
          <tr key={`table-customers-row-${index}`}>
            <td className="d-print-none">{item.id}</td>
            <td>{item.contacts.name}</td>
            <td className="text-nowrap">{item.meals_plans_title}</td>
            <td>
              {item.meals_types_names !== ""
                ? item.meals_types_names
                : item.meals_types_program}
            </td>
            <td className="text-center">
              {item.delka_tydne === "mon-fri" ? "Ano" : ""}
            </td>
            <td className="text-center">
              {item.delka_tydne === "mon-sun" ? "Ano" : ""}
            </td>
            <td className="text-center">
              {item.meals_chody_protein && item.meals_chody_protein === 1
                ? "Ano"
                : ""}
            </td>
            <td>{item.kitchen_info}</td>
          </tr>
        ))}
      </tbody>
      <tfoot className="d-print-none">
        <tr className="font-weight-bold">
          <td>Amo Id</td>
          <td>Name</td>
          <td>Program</td>
          <td>Meal types</td>
          <td>Bez sobot</td>
          <td>Neděle</td>
          <td>Protein</td>
          <td>Info pro kuchyň</td>
        </tr>
      </tfoot>
    </Table>
  );
};

const MealsTable = ({
  tableRef,
  mealsDays,
  setModalData,
  modalToggle,
}: MealsTableType) => {
  if (mealsDays && mealsDays.length) console.log("MealsTable", mealsDays);

  /*
    Customers
  */
  type CustomersProps = {
    customers: Array<CustomersType>;
  };
  const MealCustomers = ({ customers }: CustomersProps): JSX.Element => {
    if (customers.length === 0) {
      return <div>No customers..</div>;
    }

    return (
      <Table className="mt-3 text-smaller" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Program</th>
            <th>Meal types</th>
            <th>Bez sobot</th>
            <th>Neděle</th>
            <th>Protein</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((currentCustomer, currentCustomerIndex) => (
            <tr key={`tr-meal-customer-${currentCustomerIndex}`}>
              <td className="vertical-middle">{currentCustomerIndex + 1}</td>
              <td className="vertical-middle">
                {currentCustomer.contacts.name}
              </td>
              <td className="vertical-middle">
                {currentCustomer.meals_plans_title}
              </td>
              <td className="vertical-middle">
                {currentCustomer.meals_types_names !== ""
                  ? currentCustomer.meals_types_names
                  : currentCustomer.meals_types_program}
              </td>
              <td className="vertical-middle text-center">
                {currentCustomer.delka_tydne === "mon-fri" ? "Ano" : ""}
              </td>
              <td className="vertical-middle text-center">
                {currentCustomer.delka_tydne === "mon-sun" ? "Ano" : ""}
              </td>
              <td className="vertical-middle text-center">
                {currentCustomer.meals_chody_protein &&
                  currentCustomer.meals_chody_protein === 1
                  ? "Ano"
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="font-weight-bold">#</td>
            <td className="font-weight-bold">Name</td>
            <td className="font-weight-bold">Program</td>
            <td className="font-weight-bold">Meal types</td>
            <td className="font-weight-bold">Bez sobot</td>
            <td className="font-weight-bold">Neděle</td>
            <td className="font-weight-bold">Protein</td>
          </tr>
        </tfoot>
      </Table>
    );
  };

  /*
    Ingredients
  */
  type IngredientsProps = {
    ingredients: Array<IngredientsType>;
  };
  const MealIngredients = ({ ingredients }: IngredientsProps): JSX.Element => {
    if (ingredients.length === 0) {
      return <div>No ingredients..</div>;
    }

    return (
      <Table className="mt-3 text-smaller" striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ width: "70px" }}>#</th>
            <th>Ingredient</th>
            <th style={{ width: "150px" }}>Qty</th>
            <th style={{ width: "150px" }}>Netto, gr.</th>
          </tr>
        </thead>
        <tbody>
          {ingredients.map((currentIngredient, currentIngredientIndex) => (
            <tr key={`tr-meal-customer-${currentIngredientIndex}`}>
              <td className="vertical-middle">{currentIngredientIndex + 1}</td>
              <td className="vertical-middle">{currentIngredient.title}</td>
              <td className="vertical-middle">{currentIngredient.qty}</td>
              <td className="vertical-middle">
                {Math.round(currentIngredient.netto)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="font-weight-bold">#</td>
            <td className="font-weight-bold">Ingredient</td>
            <td className="font-weight-bold">Qty</td>
            <td className="font-weight-bold">Netto, gr.</td>
          </tr>
        </tfoot>
      </Table>
    );
  };

  /*
    Semifinished
  */
  type SemifinishedProps = {
    semifinished: Array<SemifinishedType>;
  };
  const MealSemifinished = ({
    semifinished,
  }: SemifinishedProps): JSX.Element => {
    if (semifinished.length === 0) {
      return <div>No semifinished..</div>;
    }

    return (
      <Table className="mt-3 text-smaller" striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ width: "70px" }}>#</th>
            <th>Semifinished</th>
            <th style={{ width: "150px" }}>Qty</th>
            <th style={{ width: "150px" }}>Netto, gr.</th>
          </tr>
        </thead>
        <tbody>
          {semifinished.map((currentSemifinished, currentSemifinishedIndex) => (
            <tr key={`tr-meal-customer-${currentSemifinishedIndex}`}>
              <td className="vertical-middle">
                {currentSemifinishedIndex + 1}
              </td>
              <td className="vertical-middle">{currentSemifinished.title}</td>
              <td className="vertical-middle">{currentSemifinished.qty}</td>
              <td className="vertical-middle">
                {Math.round(currentSemifinished.netto)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="font-weight-bold">#</td>
            <td className="font-weight-bold">Semifinished</td>
            <td className="font-weight-bold">Qty</td>
            <td className="font-weight-bold">Netto, gr.</td>
          </tr>
        </tfoot>
      </Table>
    );
  };

  return (
    <>
      {Object.keys(mealsDays).map((key, index) => {
        const mealsData = mealsDays[key];
        // console.log("MealsTable.mealsDays", key, mealsData);

        return (
          <div key={`meals-wrapper-${index}`} className="mb-5">
            <h5>
              {key} - {mealsData.meals_qty}
            </h5>

            <Table striped bordered className="mb-0">
              <thead>
                <tr>
                  <th>Meal</th>
                  <th>Type</th>
                  <th style={{ width: "150px" }}>Qty</th>
                  <th style={{ width: "150px" }}>Netto</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(mealsData.meals).map((key, index) => {
                  const mealItem = mealsData.meals[key];
                  return (
                    <tr key={`table-meals-row-${index}`}>
                      <td>{mealItem.title}</td>
                      <td>{mealItem.meals_types_title}</td>
                      <td>
                        <Button
                          variant="link"
                          className="pl-0"
                          onClick={() => {
                            setModalData({
                              title: "Customers",
                              message: "",
                              Component: (
                                <MealCustomers customers={mealItem.clients} />
                              ),
                            });
                            modalToggle();
                          }}
                        >
                          {mealItem.qty}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          className="pl-0"
                          onClick={() => {
                            setModalData({
                              title: `${mealItem.title}`,
                              message: "",
                              Component: (
                                <>
                                  <MealIngredients
                                    ingredients={mealItem.ingredients}
                                  />
                                  <MealSemifinished
                                    semifinished={mealItem.semifinished}
                                  />
                                </>
                              ),
                            });
                            modalToggle();
                          }}
                        >
                          {Math.round(mealItem.netto)} gr.
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <thead className="d-print-none">
                <tr className="font-weight-bold">
                  <td></td>
                  <td>Type</td>
                  <td>Qty</td>
                  <td>Netto</td>
                </tr>
              </thead>
            </Table>
            {mealsData.ingredients.length > 0 ? (
              <Table striped bordered className="mb-0">
                <thead>
                  <tr>
                    <th>Ingredients</th>
                    <th style={{ width: "150px" }}>Qty</th>
                    <th style={{ width: "150px" }}>Netto</th>
                  </tr>
                </thead>
                <tbody>
                  {mealsData.ingredients.map((IngredientItem, index) => (
                    <tr key={`table-meals-row-${index}`}>
                      <td>{IngredientItem.title}</td>
                      <td>{IngredientItem.qty}</td>
                      <td>{Math.round(IngredientItem.netto)} gr.</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="d-print-none">
                  <tr className="font-weight-bold">
                    <td></td>
                    <td>Qty</td>
                    <td>Netto</td>
                  </tr>
                </tfoot>
              </Table>
            ) : null}
            {mealsData.semifinished.length > 0 ? (
              <Table striped bordered className="mb-0">
                <thead>
                  <tr>
                    <th>Semifinished</th>
                    <th style={{ width: "150px" }}>Qty</th>
                    <th style={{ width: "150px" }}>Netto</th>
                  </tr>
                </thead>
                <tbody>
                  {mealsData.semifinished.map((semifinishedItem, index) => (
                    <tr key={`table-meals-row-${index}`}>
                      <td>{semifinishedItem.title}</td>
                      <td>{semifinishedItem.qty}</td>
                      <td>{Math.round(semifinishedItem.netto)} gr.</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="d-print-none">
                  <tr className="font-weight-bold">
                    <td></td>
                    <td>Qty</td>
                    <td>Netto</td>
                  </tr>
                </tfoot>
              </Table>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const Index = () => {
  let history = useHistory();

  const clientsTableRef = useRef<any>(null);
  const handleClientsPrint = useReactToPrint({
    content: () => clientsTableRef.current,
  });

  const mealsTableRef = useRef<any>(null);
  const handleMealsPrint = useReactToPrint({
    content: () => mealsTableRef.current,
  });

  const { userData, authFailed } = React.useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [selectedCookDay, setSelectedCookDay] = useState<string>("");

  const [clientsCardShow, setClientsCardShow] = useState<boolean>(true);
  const [clientsSynced, setClientsSynced] = useState<boolean>(false);
  const [clientsData, setClientsData] = useState<ClientsDataType>({
    sync_datetime: "",
    leads_syncs_id: 0,
    cook_day: "",
    cook_days: [],
    eat_days: [],
    customers: [],
    customers_count: 0,
  });

  const [mealsCardShow, setMealsCardShow] = useState<boolean>(false);
  const [mealsSynced, setMealsSynced] = useState<boolean>(false);
  const [mealsData, setMealsData] = useState<MealsDataType>({
    cooking_id: 0,
    meals: {},
    meals_bigsmall: {},
    semifinished: [],
    ingredients: [],
    meals_qty: 0,
    meals_types: [],
    clients: [],
    customers: [],
  });

  const [taskSet, setTaskSet] = useState<boolean>(false);

  const { modalIsOpen, modalToggle } = useModal();
  const [modalData, setModalData] = useState<ModalType>({
    title: "",
    message: "",
  });

  const CookDaysSelect = () => {
    return (
      <>
        <div className="col-auto">
          <h5 className="mb-0 pb-0 pt-1">Dny stravování:</h5>
        </div>
        <div className="col-auto">
          <select
            className="form-control"
            onChange={(e) => {
              setSelectedCookDay(e.target.value);
              setClientsCardShow(true);
              setMealsCardShow(false);
            }}
            value={selectedCookDay}
            onClick={(e) => { e.stopPropagation(); return false; }}
          >
            {clientsData.cook_days.map((currentCookDate, index) => (
              <option
                key={`select-cookday-${index}`}
                value={currentCookDate.cook_day}
              >
                {`${currentCookDate.eat_days.map(
                  (currentEatDate, idx) =>
                    (idx > 0 ? " - " : "") + currentEatDate.toLocaleLowerCase()
                ).join("")}`}
              </option>
            ))}
          </select>
        </div>
        {/*
        <div className="col-auto text-muted pt-1">
          Dny stravování:{" "}
          {clientsData.cook_days.map((currentCookDate, index) => {
            if (currentCookDate.cook_day === selectedCookDay) {
              return currentCookDate.eat_days.map(
                (currentEatDate, index) =>
                  (index > 0 ? ", " : "") + currentEatDate
              );
            }
          })}
        </div>
        */}
      </>

    );
  };

  const CookDaysInfo = () => {
    return (
      <div className="row mb-4">
        <div className="col-auto pt-1">
          <h5>
            Den vaření: {selectedCookDay}, dny stravování:{" "}
            {clientsData.cook_days.map((currentCookDate, index) => {
              if (currentCookDate.cook_day === selectedCookDay) {
                return currentCookDate.eat_days.map(
                  (currentEatDate, index) =>
                    (index > 0 ? ", " : "") + currentEatDate
                );
              }
            })}
          </h5>
        </div>
      </div>
    );
  };

  /**
   * AmoCRM, sync Clients
   */
  const syncWithAmoCRM = async (allowCache: boolean) => {
    setIsLoading(true);

    let url = 'kitchen/get-clients/';
    if (!allowCache)
      url += '?nocache';
    if (selectedCookDay)
      url += (!allowCache ? "&" : "?") + "cookDay=" + selectedCookDay;
    const response = await Api.getAll(
      userData.session_hash,
      url
    );

    setIsLoading(false);
    setClientsSynced(true);

    console.log("setClientsData", response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      setErrorMessage("");

      // No data?
      if (!response.data) {
        setErrorMessage("Missing reply data");
      } else {
        setClientsData(response.data);
        setSelectedCookDay(response.data.cook_day);
      }
    }
  };

  const getEatDays = () => {
    let returnDates = [] as Array<string>;
    clientsData.cook_days.map((currentCookDate, index) => {
      if (currentCookDate.cook_day === selectedCookDay) {
        returnDates = [...currentCookDate.eat_days];
      }
    });

    return returnDates;
  };

  /**
   * Cooking, confirm clients and get the meals
   */
  const getMeals = async () => {
    setIsLoading(true);
    setClientsCardShow(false);

    // cook_day=${clientsData.cook_day}
    const response = await Api.getAll(
      userData.session_hash,
      "kitchen/next-cooking/" +
      `?cook_day=${selectedCookDay}` +
      `&eat_days=${JSON.stringify(getEatDays())}` +
      `&leads_syncs_id=${clientsData.leads_syncs_id}`
    );

    setIsLoading(false);

    console.log("getMeals", response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      setErrorMessage("");

      // No data?
      if (!response.data) {
        setErrorMessage("Missing reply data");
      } else {
        setMealsSynced(true);
        setMealsData(response.data);
        setMealsCardShow(true);
      }
    }
  };

  /**
   * Send the task to the kitchen
   */
  const kitchenSend = async () => {
    setIsLoading(true);
    setClientsCardShow(false);
    setMealsCardShow(false);

    const response = await Api.getAll(
      userData.session_hash,
      `kitchen/set-task/?cooking_id=${mealsData.cooking_id}`
    );

    setIsLoading(false);

    console.log("kitchenSend", response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      setErrorMessage("");

      // Success
      setTaskSet(true);
    }
  };

  useEffect(() => {
    syncWithAmoCRM(true);
  }, [selectedCookDay]);

  /**
   * Generate xlsx with Clients
   */
  const clientsXLSX = () => {
    const clients = [
      [
        "#",
        "Jméno",
        "Program",
        "Jídla",
        "Bez sobot",
        "Neděle",
        "Protein",
        "Info pro kuchyň",
      ],
    ] as any;
    clientsData.customers.map((item, index: number) => {
      clients.push([
        clients.length,
        item.contacts.name,
        item.meals_plans_title,
        item.meals_types_names !== ""
          ? item.meals_types_names
          : item.meals_types_program,
        item.delka_tydne === "mon-fri" ? "Ano" : "",
        item.delka_tydne === "mon-sun" ? "Ano" : "",
        item.meals_chody_protein ? "Ano" : "",
        item.kitchen_info,
      ]);
    });

    const dateObj = new Date(selectedCookDay);
    const dateString: String =
      dateObj.getDate() + "." + (dateObj.getMonth() + 1);

    xlsxGenerate(`Výroba ${dateString} - Seznam klientů.xlsx`, [
      {
        title: "Klienti",
        data: clients,
      },
    ] as SheetsType);
  };

  /**
   * Generate xlsx with Tech cards for the Kitchen
   */
  const kitchenSummaryXLSX = () => {
    const sheets = [] as SheetsDetailedType;

    Object.keys(mealsData.meals).map((dayKey) => {
      const currentDay = mealsData.meals[dayKey];

      const data = [] as any;
      const merges = [] as Array<string>;
      const styles = [] as CellStyles;
      const widths = [
        { wch: 12 },
        { wch: 76 },
        { wch: 12 },
        { wch: 12 },
      ] as any;

      // Title
      data.push([czWeekDay(dayKey) + ", " + dayKey]);
      styles.push({ cell: "A1", style: { font: { sz: 14, bold: true } } });
      merges.push("A1:D1");
      data.push([""]);

      data.push(["", "Nazev", "Na 1 porce", "Celkem"]);
      utilsStyleRange(
        styles,
        {
          font: { bold: true },
          alignment: { horizontal: "right" },
          border: { bottom: { style: "thin" } },
        },
        "A",
        "D",
        data.length
      );
      styles.push({
        cell: "B" + data.length,
        style: {
          font: { bold: true },
          alignment: { horizontal: "left" },
          border: { bottom: { style: "thin" } },
        },
      });

      // Meals
      Object.keys(currentDay.meals).map((mealKey) => {
        const currentMeal = currentDay.meals[mealKey] as any;

        data.push([
          currentMeal.meals_types_title,
          currentMeal.title,
          "",
          currentMeal.qty,
        ]);
        utilsStyleRange(
          styles,
          {
            font: { bold: true },
          },
          "A",
          "D",
          data.length
        );

        // Meal components
        if (currentMeal.groups && currentMeal.groups.length > 0) {
          currentMeal.groups.map((currentGroup: GroupType) => {
            const currentGroupNetto = currentGroup.netto
              ? currentGroup.netto
              : currentGroup.netto_auto;
            const currentGroupTotalNetto = Math.round(
              currentGroupNetto * currentMeal.qty
            );

            data.push([
              "",
              currentGroup.title,
              currentGroupNetto,
              currentGroupTotalNetto,
            ]);
          });
        }

        data.push([""]);
      });

      // Add the the Sheet
      sheets.push({
        title: czWeekDay(dayKey),
        data: data,
        styles: styles,
        merges: merges,
        colWidths: widths,
      });
    });

    const dateObj = new Date(selectedCookDay);
    const dateString: String =
      dateObj.getDate() + "." + (dateObj.getMonth() + 1);

    // Generate XLSX
    xlsxGenerateStyled(`Výroba ${dateString} - Denní karty.xlsx`, sheets);
  };

  return (
    <>
      <Row className="list">
        <Col>
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? <div className="lds-dual-ring"></div> : null}
          <>
            {/* <h5 className="mt-2 mb-2">
              Cooking day:{" "}
              {clientsData.cook_day !== "" ? clientsData.cook_day : "-"}
            </h5>
            <h5 className="mt-2 mb-4">
              Eat days:{" "}
              {clientsData.eat_days.map(
                (item, index) => (index > 0 ? ", " : "") + item
              )}
              </h5> */}
            <div className="accordion">
              {/* Clients */}
              <div className="card">
                <div
                  className="card-header"
                  onClick={() => {
                    setClientsCardShow((prevCheck) => !prevCheck);
                  }}
                >
                  <div className="row">
                    <div className="col-auto mb-0 pb-0 pt-1">
                      <h5>Krok 1. </h5>
                    </div>
                    <CookDaysSelect />
                    <div className="col-auto mb-0 pb-0 pt-1">
                      Klienti ({clientsData.customers_count})
                    </div>
                  </div>
                </div>
                <div className={`collapse${clientsCardShow ? "show" : ""}`}>
                  <div className="card-body">
                    {!taskSet ? (
                      <Row>
                        <Col className="mb-3">
                          <Button
                            onClick={() => {
                              syncWithAmoCRM(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faSync} className="mr-2" />
                            Aktualizace z AmoCRM
                          </Button>
                          &nbsp; (last sync: {clientsData.sync_datetime})
                        </Col>
                      </Row>
                    ) : null}
                    {clientsSynced ? (
                      clientsData.customers_count === 0 ? (
                        <h6>-- No customers data :(</h6>
                      ) : (
                        <div className="mb-5">
                          <CustomersTable
                            tableRef={clientsTableRef}
                            customers={clientsData.customers}
                          />

                          {/* <div className="mt-4">
                            <Button onClick={handleClientsPrint}>
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faPrint}
                              />
                              Seznam klienty
                            </Button>

                            <Button className="ml-3" onClick={clientsXLSX}>
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faFileExcel}
                              />
                              Stažení seznamu klientů
                            </Button>
                          </div> */}

                          {!taskSet ? (
                            <div className="mt-5">
                              <div className="row">
                                <div className="col-auto">
                                  <Button variant="success" onClick={getMeals}>
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      icon={faCheckCircle}
                                    />
                                    Krok 2. Spočítat jídla &raquo;
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Meals */}
              <div className="card mb-5">
                <div
                  className="card-header"
                  onClick={() => {
                    setMealsCardShow((prevCheck) => !prevCheck);
                  }}
                >
                  <h5>Krok 2. Počet jídel ({mealsData.meals_qty})</h5>
                </div>
                <div className={`collapse${mealsCardShow ? "show" : ""}`}>
                  <div className="card-body">
                    {mealsSynced ? (
                      <>
                        <CookDaysInfo />

                        <div className="mb-5">
                          <MealsTable
                            tableRef={mealsTableRef}
                            mealsDays={mealsData.meals}
                            setModalData={setModalData}
                            modalToggle={modalToggle}
                          />
                        </div>

                        <div className="mb-5">
                          <h5>Semifinished (total)</h5>
                          {mealsData.semifinished.length > 0 ? (
                            <Table striped bordered className="mb-5">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{ width: "150px" }}>Qty</th>
                                  <th style={{ width: "150px" }}>Netto</th>
                                </tr>
                              </thead>
                              <tbody>
                                {mealsData.semifinished.map(
                                  (semifinishedItem, index) => (
                                    <tr key={`table-meals-row-${index}`}>
                                      <td>{semifinishedItem.title}</td>
                                      <td>{semifinishedItem.qty}</td>
                                      <td>
                                        {Math.round(semifinishedItem.netto)} gr.
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                              <tfoot className="d-print-none">
                                <tr className="font-weight-bold">
                                  <td></td>
                                  <td>Qty</td>
                                  <td>Netto</td>
                                </tr>
                              </tfoot>
                            </Table>
                          ) : null}
                        </div>

                        <div className="mb-5">
                          <h5>
                            Ingredients (total, with semifinished ingredients)
                          </h5>
                          {mealsData.ingredients.length > 0 ? (
                            <Table striped bordered className="mb-5">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th style={{ width: "150px" }}>Qty</th>
                                  <th style={{ width: "150px" }}>Netto</th>
                                </tr>
                              </thead>
                              <tbody>
                                {mealsData.ingredients.map(
                                  (ingredientsItem, index) => (
                                    <tr key={`table-meals-row-${index}`}>
                                      <td>{ingredientsItem.title}</td>
                                      <td>{ingredientsItem.qty}</td>
                                      <td>
                                        {Math.round(ingredientsItem.netto)} gr.
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                              <tfoot className="d-print-none">
                                <tr className="font-weight-bold">
                                  <td></td>
                                  <td>Qty</td>
                                  <td>Netto</td>
                                </tr>
                              </tfoot>
                            </Table>
                          ) : null}
                        </div>

                        <div className="mb-5">
                          <Button
                            className="ml-3"
                            onClick={() =>
                              prehledXLSX(selectedCookDay, mealsData)
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFileExcel}
                            />
                            Přehled
                          </Button>

                          <Button
                            className="ml-3"
                            onClick={() =>
                              techCardsXLSX(selectedCookDay, mealsData.meals)
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFileExcel}
                            />
                            Technické karty
                          </Button>

                          <Button
                            className="ml-3"
                            onClick={() =>
                              techCardsAddendumXLSX(
                                selectedCookDay,
                                mealsData.meals_bigsmall
                              )
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFileExcel}
                            />
                            Technické karty (dodatek)
                          </Button>

                          <Button
                            className="ml-3"
                            onClick={() => kitchenSummaryXLSX()}
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFileExcel}
                            />
                            Denní karty
                          </Button>

                          <Button
                            className="ml-3"
                            onClick={() =>
                              samolepkyXLSX(
                                selectedCookDay,
                                mealsData.customers
                              )
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faFileExcel}
                            />
                            Samolepky
                          </Button>

                          {/* !taskSet ? (
                            <Button
                              className="ml-3"
                              variant="success"
                              onClick={kitchenSend}
                            >
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faCheckCircle}
                              />
                              Poslat do kuchyně
                            </Button>
                          ) : null */}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        </Col>
      </Row>

      {taskSet ? (
        <Row>
          <Col>
            <h3>🎉 Hotovo</h3>
          </Col>
        </Row>
      ) : null}

      {modalData ? (
        <Modal
          title={modalData.title}
          message={modalData.message}
          Component={modalData.Component}
          isOpen={modalIsOpen}
          toggle={modalToggle}
          size="lg"
        />
      ) : null}
    </>
  );
};

export default Index;
