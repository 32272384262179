import React, { useState, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Table, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faStar,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

import useModal from "hooks/useModal";
import Modal from "components/elements/Modal";

type daysType = string;

type mealsTypesType = {
  id: number;
  title: string;
};

type mealsRatesType = {
  comment: string;
  datetime_added: string;
  id: number;
  phone: number | string;
  name: string;
  rate: number;
  rate_details: Array<string | number>;
};

type mealsTypes = {
  id: number;
  title: string;
  rate: number;
  rate_qty: number;
  meal_rates: Array<mealsRatesType>;
};

type mealTypesConnType = {
  [key: string]: mealsTypes;
};

type initialDataType = {
  id: number;
  date_from: string;
  date_to: string;
  week_title: string;
  days_available: Array<daysType>;
  meals_types_available: Array<mealsTypesType>;
  // meals_available: Array<mealsTypesType>;
  meals: mealTypesConnType;
};

/* type buttonType = {
  title: string;
  onClick: () => void;
}; */
type ModalType = {
  title: string;
  message: string;
  // button?: buttonType;
  Component?: JSX.Element;
};

const Rates = (props: any) => {
  console.log("Rates", props);
  const section = "rates";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { modalIsOpen, modalToggle } = useModal();
  const [modalData, setModalData] = useState<ModalType>({
    title: "",
    message: "",
  });

  const initialDataState = {
    id: 0,
    date_from: "",
    date_to: "",
    week_title: "",
    days_available: [],
    meals_types_available: [],
    // meals_available: [],
    meals: {},
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, `menu-${section}`, id)
      .then((response: any) => {
        console.log("Rates.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/menu/calendar/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <>
              <h3>{initialData.week_title}</h3>
              <h5>
                {initialData.date_from} → {initialData.date_to}
              </h5>
              <Table className="uc-weeks-rates mt-3" striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    {initialData.days_available.map((day, dindex) => (
                      <th className="text-center" key={`th-day-${dindex}`}>
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {initialData.meals_types_available.map((meal, mindex) => (
                    <tr key={`tr-meal-${mindex}`}>
                      <td className="text-nowrap vertical-middle font-weight-bold">
                        {meal.title}
                      </td>
                      {initialData.days_available.map((day, dindex) => {
                        const currentMeal =
                          initialData.meals[meal.id + "-" + day] !== undefined
                            ? initialData.meals[meal.id + "-" + day]
                            : ({} as mealsTypes);

                        /*
                            Compile rates
                          */
                        const MealRates = (): JSX.Element => {
                          if (currentMeal.meal_rates.length === 0) {
                            return <div>No rates yet..</div>;
                          }

                          return (
                            <Table
                              className="mt-3 text-smaller"
                              striped
                              bordered
                              hover
                              size="sm"
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: "130px" }}>Client</th>
                                  {/* <th>Date</th> */}
                                  <th
                                    style={{ width: "50px" }}
                                    className="text-center"
                                  >
                                    Rate
                                  </th>
                                  <th>Comment / details</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentMeal.meal_rates.map(
                                  (currentRate, currentRateIndex) => (
                                    <tr
                                      key={`tr-meal-rate-${currentRateIndex}`}
                                      className={`${(() => {
                                        if (!currentRate.rate) return "";
                                        switch (true) {
                                          case currentRate.rate > 0 &&
                                            currentRate.rate <= 3:
                                            return " table-danger";
                                          case currentRate.rate > 3 &&
                                            currentRate.rate <= 4:
                                            return " table-warning";
                                          case currentRate.rate > 4:
                                            return " table-success";
                                          default:
                                            return "";
                                        }
                                      })()}`}
                                    >
                                      <td className="vertical-middle">
                                        {currentRate.name !== ""
                                          ? currentRate.name
                                          : currentRate.phone}
                                      </td>
                                      {/* <td className="vertical-middle">
                                        {currentRate.datetime_added.substring(
                                          0,
                                          currentRate.datetime_added.length - 3
                                        )}
                                        </td> */}
                                      <td className="vertical-middle text-center">
                                        {currentRate.rate}
                                      </td>
                                      <td className="vertical-middle">
                                        {currentRate.comment}
                                        {currentRate.rate_details.length > 0 ? (
                                          <>
                                            {currentRate.comment !== "" ? (
                                              <br />
                                            ) : (
                                              ""
                                            )}
                                            {currentRate.rate_details.map(
                                              (
                                                currentRateDetail,
                                                cRateDetailIndex
                                              ) => {
                                                return (
                                                  <span
                                                    key={`span-meal-rate-${currentRateIndex}-details-${cRateDetailIndex}`}
                                                    className="badge badge-pill badge-primary mr-2 mt-1"
                                                    style={{
                                                      fontSize: "12px",
                                                      fontWeight: "normal",
                                                    }}
                                                  >
                                                    {(() => {
                                                      switch (
                                                        currentRateDetail
                                                      ) {
                                                        case 1:
                                                          return "Vzhled";
                                                        case 2:
                                                          return "Struktura";
                                                        case 3:
                                                          return "Chuť";
                                                        case 4:
                                                          return "Velikost porce";
                                                        case 5:
                                                          return "Balení donášky";
                                                        default:
                                                          return "";
                                                      }
                                                    })()}
                                                  </span>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          );
                        };

                        return (
                          <td
                            style={{ cursor: "pointer", position: "relative" }}
                            className={`text-smaller text-center vertical-middle${(() => {
                              if (!currentMeal?.rate) return "";
                              switch (true) {
                                case currentMeal.rate > 0 &&
                                  currentMeal.rate <= 3:
                                  return " alert-danger";
                                case currentMeal.rate > 3 &&
                                  currentMeal.rate <= 4:
                                  return " alert-warning";
                                case currentMeal.rate > 4:
                                  return " alert-success";
                                default:
                                  return "";
                              }
                            })()}`}
                            key={`td-day-${dindex}`}
                            onClick={(e) => {
                              if (
                                (e.target as HTMLTextAreaElement).matches(
                                  ".meal-item-link, .meal-item-link *"
                                )
                              ) {
                                return false;
                              }

                              setModalData({
                                title: currentMeal?.title
                                  ? currentMeal.title
                                  : "",
                                message: "",
                                Component: <MealRates />,
                                /*button: {
                                  title: "Yes",
                                  onClick: () => {
                                    console.log("yes");

                                    const newIngredients = values.ingredients.filter(
                                      function (obj) {
                                        return obj.id !== item.id;
                                      }
                                    );

                                    setFieldValue(
                                      "ingredients",
                                      newIngredients
                                    );

                                    recalcTotalNumbers(
                                      values.semifinished,
                                      newIngredients
                                    );
                                  },
                                },*/
                              });
                              modalToggle();
                            }}
                          >
                            {currentMeal?.title ? currentMeal.title : ""}
                            {currentMeal?.rate ? (
                              <div style={{ marginTop: "10px" }}>
                                <FontAwesomeIcon
                                  style={{ opacity: "0.5" }}
                                  icon={faStar}
                                />
                                &nbsp;
                                {currentMeal.rate}
                                &nbsp; ({currentMeal.rate_qty})
                              </div>
                            ) : (
                              ""
                            )}
                            {userData.roles !== undefined &&
                            userData.roles.length > 0 &&
                            userData.roles.includes("rating") ? null : (
                              <Link
                                to={`/meals/all/edit/${currentMeal.id}`}
                                className="meal-item-link"
                                style={{
                                  position: "absolute",
                                  top: "1px",
                                  right: "1px",
                                  backgroundColor: "rgba(255,255,255,0.5)",
                                  borderRadius: "10px",
                                  padding: "3px 7px",
                                }}
                                href="#"
                              >
                                <FontAwesomeIcon icon={faUtensils} />
                              </Link>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>

      {modalData ? (
        <Modal
          title={modalData.title}
          message={modalData.message}
          Component={modalData.Component}
          isOpen={modalIsOpen}
          toggle={modalToggle}
          size="lg"
        />
      ) : null}
    </>
  );
};

export default Rates;
