import {
  xlsxGenerateStyled,
  SheetsDetailedType,
  CellStyles,
  utilsStyleRange,
} from "utils/xlsx";

type CustomersType = {
  id: number;
  contacts: ContactsType;
  meals_plans_title: string;
  kitchen_info?: string;
  delka_tydne?: string;
  meals_types_names?: string;
  meals_types_names_nums?: string;
  meals_types_replaced?: MealReplacedType;
  meals_types_replaced_nums?: MealReplacedType;
  meals_types_program?: string;
  meals_types_program_nums?: string;
  meals_chody_protein?: number;
};

type ContactsType = {
  name: string;
  phones: Array<string>;
  emails: Array<string>;
};

type MealReplacedType = {
  [key: string]: string;
};

/**
 * Document with clients names for Bags stickers
 */
export const samolepkyXLSX = (
  selectedCookDay: string,
  customers: Array<CustomersType>
) => {
  const sheets = [] as SheetsDetailedType;
  const widths = [] as any;
  for (var i = 0; i < 3; i++) widths.push({ wpx: 218 });
  const heights = [] as any;
  for (var i = 0; i < 8; i++) heights.push({ hpx: 128 });

  let rowNames = [] as any;
  let sheetRows = [] as any;
  let styles = [] as CellStyles;

  customers.map((item, index: number) => {
    rowNames.push([item.contacts.name]);

    if (rowNames.length >= 3) {
      sheetRows.push(rowNames);
      rowNames = [];

      utilsStyleRange(
        styles,
        {
          font: { bold: true, sz: 24, name: "Arial" },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: {
            left: { style: "thin", color: { rgb: "FFD9D9D9" } },
            right: { style: "thin", color: { rgb: "FFD9D9D9" } },
            top: { style: "thin", color: { rgb: "FFD9D9D9" } },
            bottom: { style: "thin", color: { rgb: "FFD9D9D9" } },
          },
        },
        "A",
        "C",
        sheetRows.length
      );
    }

    if (sheetRows.length >= 8) {
      sheets.push({
        title: "Page " + (sheets.length + 1),
        data: sheetRows,
        colWidths: widths,
        rowHeights: heights,
        styles: styles,
      });

      sheetRows = [];
      styles = [];
    }
  });

  if (rowNames.length > 0) {
    sheetRows.push(rowNames);

    utilsStyleRange(
      styles,
      {
        font: { bold: true, sz: 24, name: "Arial" },
        alignment: { vertical: "center", horizontal: "center" },
        border: {
          left: { style: "thin", color: { rgb: "FFD9D9D9" } },
          right: { style: "thin", color: { rgb: "FFD9D9D9" } },
          top: { style: "thin", color: { rgb: "FFD9D9D9" } },
          bottom: { style: "thin", color: { rgb: "FFD9D9D9" } },
        },
      },
      "A",
      "C",
      sheetRows.length
    );
  }

  if (sheetRows.length > 0) {
    sheets.push({
      title: "Page " + (sheets.length + 1),
      data: sheetRows,
      colWidths: widths,
      rowHeights: heights,
      styles: styles,
    });
  }

  const dateObj = new Date(selectedCookDay);
  const dateString: String = dateObj.getDate() + "." + (dateObj.getMonth() + 1);

  xlsxGenerateStyled(`Výroba ${dateString} - Samolepky pro tasky.xlsx`, sheets);
};
