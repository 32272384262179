import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";

registerLocale("en-GB", enGB);

type DatePickerFieldType = {
  name: string;
  value: string;
  onChange: any;
  isInvalid: boolean;
};

const DatePickerField = ({
  name,
  value,
  onChange,
  isInvalid,
}: DatePickerFieldType) => {
  console.log("DatePickerField", value);
  return (
    <DatePicker
      selected={(value && new Date(value)) || null}
      onChange={(val: any) => {
        // var todayDate = val.toISOString().slice(0, 10);
        console.log("DatePickerField.onChange", name, val);

        onChange(name, val);
      }}
      className={`form-control${isInvalid ? " is-invalid" : ""}`}
      dateFormat="yyyy-MM-dd"
      locale="en-GB"
    />
  );
};

export default DatePickerField;
