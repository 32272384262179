import axios from "axios";

const API_URL = "https://mgr.uncook.app/api/auth/";

const getAuthHeader = (sessionHash: string): { Authorization?: string } => {
  console.log("auth.getAuthHeader", sessionHash);

  if (sessionHash) {
    return { Authorization: "Bearer " + sessionHash };
  } else {
    return {};
  }
};

const login = (username: string, password: string) => {
  console.log("auth.login", username);

  return axios
    .post(API_URL + "login/", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }

      return response.data;
    });
};

const logout = (sessionHash: string) => {
  const userHeaders = getAuthHeader(sessionHash);
  console.log("auth.logout", userHeaders);

  if (!userHeaders || !userHeaders?.Authorization) return false;

  return axios
    .post(
      API_URL + "logout/",
      {},
      {
        headers: userHeaders,
      }
    )
    .then((response) => {
      localStorage.removeItem("user");

      return response.data;
    });
};

const getCurrentUser = () => {
  const userData = localStorage.getItem("user");
  if (typeof userData !== "string") return {};
  return JSON.parse(userData);
};

export default {
  login,
  logout,
  getCurrentUser,
  getAuthHeader,
};
