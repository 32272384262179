/**
 * Compare two versions like "1.0.6" and "1.0.9"
 * and will return:
 * @doc https://stackoverflow.com/a/6832721
 * @param {String} New version
 * @param {String} Old version
 * @param {Array} Comparison options
 * @return {Numeric} 0: equal, 1: New>Old, -1: Old>New
 */
export function versionsCompare(v1: string, v2: string) {
  // , options: Object
  var lexicographical = false, // options && options.lexicographical,
    zeroExtend = false, // options && options.zeroExtend,
    v1parts = v1.split(".").map(Number),
    v2parts = v2.split(".").map(Number);

  function isValidPart(x: Number) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x.toString());
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push(0);
    while (v2parts.length < v1parts.length) v2parts.push(0);
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}
