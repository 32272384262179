import React, { useState, useRef } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

import AuthService from "../../services/auth";
import { UserContext } from "../../contexts/UserContext";

const schema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
});

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const { logIn } = React.useContext(UserContext);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto" className="p-3">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : (
            ""
          )}
          <Formik
            validationSchema={schema}
            onSubmit={(formData, { setSubmitting }) => {
              setErrorMessage("");

              AuthService.login(formData.username, formData.password).then(
                (data) => {
                  console.log("AuthService.data", data);

                  if (data.error) {
                    setErrorMessage(data.error);
                  } else {
                    logIn(data.data);
                  }

                  setSubmitting(false);

                  // props.history.push("/profile");
                  // window.location.reload();
                },
                (error) => {
                  console.log("AuthService.error", error);

                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();

                  setErrorMessage(resMessage);
                  setSubmitting(false);
                  // setMessage(resMessage);
                }
              );
            }}
            initialValues={{
              username: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" disabled={isSubmitting}>
                  Authorize
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
