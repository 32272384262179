import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

type unitsTypes = {
  id: number;
  title: string;
};

type ingredientsTypes = {
  id: number;
  title: string;
  units_name: string;
  loss_percent: number;
  brutto: number;
  netto: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  units_id: number;
  ks_qty: number;
};

type initialDataType = {
  id: number;
  title: string;
  amount?: number | string;
  units_id: number;
  kcal?: number | string;
  protein?: number | string;
  fat?: number | string;
  carbs?: number | string;
  recipe?: string;
  units: Array<unitsTypes>;
  ingredients: Array<ingredientsTypes>;
  ingredients_available: Array<ingredientsTypes>;
  ingredients_selector: string;
};

type TotalNumbersType = {
  brutto: number;
  netto: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
};

const Print = (props: any) => {
  console.log("Print", props);
  const section = "semifinished";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalIngredients, setTotalIngredients] = useState<TotalNumbersType>({
    brutto: 0,
    netto: 0,
    kcal: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });

  const initialDataState = {
    id: 0,
    title: "",
    amount: "",
    units_id: 0,
    recipe: "",
    units: [],
    ingredients: [],
    ingredients_available: [],
    ingredients_selector: "",
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = (id: number) => {
    Api.getOne(userData.session_hash, `meals-${section}`, id)
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData(props.match.params.id);
  }, [props.match.params.id]);

  React.useEffect(() => {
    if (initialData.ingredients && initialData.ingredients.length > 0) {
      recalcTotalNumbers(initialData.ingredients);

      // Print!
      setTimeout(function () {
        window.print();
      }, 300);
    }
  }, [initialData]);

  const recalcTotalNumbers = (newIngredients: Array<ingredientsTypes>) => {
    let newTotals = {
      brutto: 0,
      netto: 0,
      kcal: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
    };

    newIngredients.map((item, index) => {
      let itemBrutto = parseFloat(item.brutto as any);
      let itemNetto = parseFloat(item.netto as any);

      newTotals.brutto += isNaN(itemBrutto)
        ? 0
        : itemBrutto < 0
        ? 0
        : itemBrutto;
      newTotals.netto += isNaN(itemNetto) ? 0 : itemNetto < 0 ? 0 : itemNetto;

      const foundIndex = initialData.ingredients_available.findIndex(
        (x) => x.id == item.id
      );
      const originalIngredient = initialData.ingredients_available[foundIndex];

      const itemPercent = (item.netto * 100) / originalIngredient.netto;
      newTotals.kcal += originalIngredient.kcal * (itemPercent / 100);
      newTotals.protein += originalIngredient.protein * (itemPercent / 100);
      newTotals.fat += originalIngredient.fat * (itemPercent / 100);
      newTotals.carbs += originalIngredient.carbs * (itemPercent / 100);
    });

    newTotals.brutto = Math.round(newTotals.brutto * 100) / 100;
    newTotals.netto = Math.round(newTotals.netto * 100) / 100;
    newTotals.kcal = Math.round(newTotals.kcal * 100) / 100;
    newTotals.protein = Math.round(newTotals.protein * 100) / 100;
    newTotals.fat = Math.round(newTotals.fat * 100) / 100;
    newTotals.carbs = Math.round(newTotals.carbs * 100) / 100;
    setTotalIngredients(newTotals);
  };

  return (
    <>
      <Row className="d-print-none">
        <Col>
          <h3>
            <Link to={`/meals/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col xl="10">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <>
              <Row>
                <Col>
                  <h3>{initialData.title}</h3>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Table striped bordered hover className="table-middle">
                    <thead>
                      <tr>
                        <th>Váha</th>
                        <th>kCal</th>
                        <th>Bilkoviny</th>
                        <th>Sacharidy</th>
                        <th>Tuky</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {initialData.amount}&nbsp;
                          {initialData.units.map((type) => (
                            <>
                              {type.id === initialData.units_id
                                ? type.title
                                : ""}
                            </>
                          ))}
                        </td>
                        <td>{totalIngredients.kcal}</td>
                        <td>{totalIngredients.protein}</td>
                        <td>{totalIngredients.carbs}</td>
                        <td>{totalIngredients.fat}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Form.Row className="my-3">
                <Form.Group as={Col}>
                  <div className="uc-hrdivider mt-4">
                    <hr />
                    <span>Ingredients</span>
                  </div>
                  <Table striped bordered hover className="table-middle">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Unit</th>
                        <th>%</th>
                        <th>Brutto</th>
                        <th>Netto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initialData.ingredients.map((item, index) => (
                        <tr key={`table-row-${item.id}`}>
                          <td>{item.title}</td>
                          <td>{item.units_name}</td>
                          <td>{item.loss_percent}</td>
                          <td>
                            {item.units_id === 1 ? (
                              <>
                                {item.ks_qty} ks&nbsp;({item.brutto} gr)
                              </>
                            ) : (
                              item.brutto
                            )}
                          </td>
                          <td>
                            {item.units_id === 1 ? (
                              <>
                                {item.ks_qty} ks&nbsp;({item.netto} gr)
                              </>
                            ) : (
                              item.netto
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th colSpan={3}></th>
                        <th>{totalIngredients.brutto}</th>
                        <th>{totalIngredients.netto}</th>
                      </tr>
                    </tbody>
                  </Table>
                </Form.Group>
              </Form.Row>

              <Row>
                <Col>
                  <h5>Recept</h5>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  {initialData.recipe !== undefined
                    ? initialData.recipe
                        .split("\n")
                        .map((it: string, i: number) => (
                          <p key={"x" + i}>{it}</p>
                        ))
                    : null}
                </Col>
              </Row>

              <Row className="mt-5 mb-5 pb-5 d-print-none">
                <Col>
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Print again
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Print;
