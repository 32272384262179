import axios from "axios";
import AuthService from "./auth";

const API_URL = "https://mgr.uncook.app/api/";

/* const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: AuthService.getAuthHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: AuthService.getAuthHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getAdminBoard,
};
 */

// import http from "../http-common";

const imagesUpload = async ({
  uid,
  file,
  send,
  apiUrl,
  headers,
  onProgress,
  innerOnSuccess,
  onError,
  sessionHash,
  setErrorMessage,
}: any) => {
  const action = API_URL + apiUrl;
  // console.log("api.imagesUpload", action);

  const form = new FormData();

  // send file
  form.append("file", file);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const userHeaders = AuthService.getAuthHeader(sessionHash);

  axios
    .post(action, form, {
      headers: userHeaders,
      onUploadProgress: ({ total, loaded }) => {
        onProgress(uid, Math.round((loaded / total) * 100));
      },
      cancelToken: source.token,
    })
    .then(({ data: response }) => {
      console.log("RUG.response", response);

      if (response.error) {
        setErrorMessage("Image upload error: " + response.error);
        onError(uid, {
          action,
          status: 400,
          response: response.error,
        });
      } else {
        innerOnSuccess(uid, response);
      }
    })
    .catch((error) => {
      onError(uid, {
        action,
        status: error.request,
        response: error.response,
      });
    });

  return {
    abort() {
      source.cancel();
    },
  };

  /* try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.get(API_URL + object, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  } */
};

const imagesDelete = async (session: string, object: string) => {
  console.log("api.imagesDelete", object);

  try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.get(API_URL + object, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  }
};

const getAll = async (session: string, object: string) => {
  console.log("api.getAll", object);

  try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.get(API_URL + object, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  }
};

const getOne = async (session: string, object: string, id: number) => {
  console.log("api.getOne", object, id);

  try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.get(API_URL + `${object}/get/${id}/`, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  }
};

const add = async (session: string, object: string, data: object) => {
  console.log("api.add", object, data);

  try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.post(API_URL + `${object}/add/`, data, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  }
};

const edit = async (
  session: string,
  object: string,
  id: number,
  data: object
) => {
  console.log("api.edit", object, id, data);

  try {
    const userHeaders = AuthService.getAuthHeader(session);

    const response = await axios.post(API_URL + `${object}/edit/${id}/`, data, {
      headers: userHeaders,
    });

    const processed = processResponse(response);

    return processed;
  } catch (e) {
    return {
      error: "Processing error: " + e.Message,
    };
  }
};

const processResponse = (response: any) => {
  if (!response.data) {
    return { error: "Invalid reply" };
  }

  if (response.data.error) {
    if (response.data.error === "Authorization is required") {
      return { authFailed: true };
    }

    return { error: response.data.error };
  }

  if (!response.data.success) {
    return { error: "Operation failed" };
  }

  return { data: response.data.data };
};

/*const remove = (session: string, object: string, id: number) => {
  console.log("api.remove", object, id);

  const userHeaders = AuthService.getAuthHeader(session);

  return axios.delete(API_URL + `${object}/${id}/`);
};*/

/*
const findByTitle = title => {
  return axios.get(`/tutorials?title=${title}`);
};
*/

export default {
  imagesUpload,
  imagesDelete,
  getAll,
  getOne,
  add,
  edit,
  // remove,
  // findByTitle
};
