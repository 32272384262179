import XLSX from "xlsx";

// https://github.com/ShanaMaid/sheetjs
import XLSXStyled from "sheetjs-style";

export type SheetsType = Array<SheetType>;
type SheetType = {
  title: string;
  data: any;
};

export type SheetsDetailedType = Array<SheetDetailedStyledType>;
type SheetDetailedStyledType = {
  title: string;
  data: any;
  styles?: CellStyles;
  merges?: Array<string>;
  colWidths?: Array<any>;
  rowHeights?: Array<any>;
};

export type CellStyles = Array<StyleType>;
type StyleType = {
  cell: string;
  style: any;
};

/**
 * Create an XLSX file
 */
export const xlsxGenerate = (fileName: string, sheets: SheetsType) => {
  // Create blank XLS document
  const wb = XLSX.utils.book_new();

  // Add sheets with data to it
  sheets.map((currentSheet, index) => {
    const ws = XLSX.utils.aoa_to_sheet(currentSheet.data);
    /* console.log(ws);
    ws.getCell("A1").font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      underline: true,
      bold: true,
    };*/
    XLSX.utils.book_append_sheet(wb, ws, currentSheet.title);
  });

  // Generate XLSX file and send to browser
  XLSX.writeFile(wb, fileName);
};

/**
 * Create an XLSX file
 */
export const xlsxGenerateStyled = (
  fileName: string,
  sheets: SheetsDetailedType
) => {
  // Create blank XLS document
  const wb = XLSXStyled.utils.book_new();

  // Add sheets with data to it
  sheets.map((currentSheet, index) => {
    const ws = XLSXStyled.utils.aoa_to_sheet(currentSheet.data);

    // Column width in characters (https://github.com/SheetJS/sheetjs#column-properties)
    ws["!cols"] = currentSheet.colWidths
      ? currentSheet.colWidths
      : [{ wch: 2 }];

    if (currentSheet.rowHeights !== undefined && currentSheet.rowHeights) {
      ws["!rows"] = currentSheet.rowHeights;
    }

    // Document margins
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.25,
      bottom: 0.25,
      header: 0.3,
      footer: 0.3,
    };
    // console.log(ws);

    // Cell Merging
    if (currentSheet.merges) {
      currentSheet.merges.map((item) => {
        if (!ws["!merges"]) ws["!merges"] = [];
        ws["!merges"].push(XLSXStyled.utils.decode_range(item));
      });
    }

    // Styling
    if (currentSheet.styles && currentSheet.styles.length) {
      currentSheet.styles.map((item) => {
        if (ws[item.cell]) {
          ws[item.cell].s = item.style;
        }
      });
    }

    /*
    ws["A1"].s = {
      // set the style for target cell
      font: {
        // name: '宋体',
        sz: 24,
        bold: true,
        // color: { rgb: "FFFFAA00" }
      },
    };
    */

    /* ws.getCell("A1").font = {
      name: "Comic Sans MS",
      family: 4,
      size: 16,
      underline: true,
      bold: true,
    };*/

    XLSXStyled.utils.book_append_sheet(wb, ws, currentSheet.title);

    /*
    let retry = false;
    let attempt = 0;
    do {
      try {
        console.log("XLSXStyled.Append: ", currentSheet.title);
        XLSXStyled.utils.book_append_sheet(wb, ws, currentSheet.title);
      } catch (e) {
        retry = true;
        attempt++;
        currentSheet.title =
          attempt.toString() + currentSheet.title.substring(0, 20);

        console.log("Soft exception:", attempt, currentSheet.title, e);

        if (attempt > 3) throw e;
      }
    } while (retry);
    */
  });

  // Generate XLSX file and send to browser
  XLSXStyled.writeFile(wb, fileName);
};

/**
 * Apply styling to specific range of cols in a row
 */
export const utilsStyleRange = (
  stylesList: Array<any>,
  applyStyle: any,
  colFrom: string,
  colTo: string,
  row: number
) => {
  const range = genCharArray(colFrom, colTo);

  range.map((column) => {
    stylesList.push({
      cell: column + row,
      style: applyStyle,
    });
  });
};

const genCharArray = (charA: string, charZ: string): Array<string> => {
  var a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i).toUpperCase());
  }
  return a;
};
