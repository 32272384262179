import React, { useState, useEffect } from "react";

// import ApiService from "../services/api";

const Dashboard = () => {
  const [content, setContent] = useState("Hello there!");

  /* useEffect(() => {
    ApiService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []); */

  return (
    <header className="jumbotron">
      <h3>{content}</h3>
    </header>
  );
};

export default Dashboard;
