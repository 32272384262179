import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Table, Row, Col, Alert, Form, Badge } from "react-bootstrap";

/**
 * Lib:
 * https://github.com/turrione/react-bootstrap-pagination
 */
import Pagination from "react-bootstrap-4-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faPencilAlt,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "contexts/UserContext";
import Api from "services/api";

type listType = {
  id: number;
  title: string;
  amount: number;
  units_name: string;
  semifinished: number;
  ingredients: number;
  kcal: number;
  rate: number;
};

const List = () => {
  const section = "all";

  let history = useHistory();
  const { userData, authFailed } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState<listType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [search, setSearch] = useState<string>(""),
    onSearchInput = (e: any) => {
      const searchTitle = e?.target?.value ? e.target.value : "";
      setSearch(searchTitle);
    },
    onSearchSubmit = (e: any) => {
      e.preventDefault();
      retrieveList(1);
    };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isLoading) retrieveList(1);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const [paginationConfig, setPaginationConfig] = React.useState({
    totalPages: 1,
    currentPage: 1,
    showMax: 20,
    center: false,
    className: "mt-3 uc-pagination-wrapper",
    // size: "lg",
    threeDots: true,
    prevNext: true,
    onClick: function (page: number) {
      console.log("paginationConfig.onClick", page);

      setIsLoading(true);
      retrieveList(page);
    },
  });

  // Load once on init
  useEffect(() => {
    retrieveList(paginationConfig.currentPage);
  }, []);

  const retrieveList = async (page: number) => {
    const response = await Api.getAll(
      userData.session_hash,
      `meals-${section}/list/?page=${page}&per_page=${paginationConfig.showMax}&search=${search}`
    );
    setIsLoading(false);

    console.log("retrieveList", page, response);

    if (response.error) {
      setErrorMessage(response.error);
    } else if (response.authFailed) {
      authFailed();
      localStorage.removeItem("user");
      history.push("/");
    } else {
      console.log("retrieveList.set", response.data);
      setErrorMessage("");
      setList(response.data.entries);

      setPaginationConfig((prevState) => {
        const newPagination = {
          ...prevState,
          ...{
            totalPages: response.data.pages,
            currentPage: page,
          },
        };

        console.log("setPaginationConfig", newPagination);

        return newPagination;
      });
    }
  };

  return (
    <>
      <Row>
        <Col className="mb-2">
          <h3>
            <Link to={`/meals/${section}/add`}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="list">
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <>
              <Form onSubmit={onSearchSubmit}>
                <Form.Row className="mb-3">
                  <Col xs="auto">
                    <Form.Control
                      placeholder="Search"
                      onChange={onSearchInput}
                      value={search}
                    />
                  </Col>
                </Form.Row>
              </Form>
              {list && list.length ? (
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Meal</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">Unit</th>
                        <th className="text-center">Semifinished</th>
                        <th className="text-center">Ingredients</th>
                        <th className="text-center">kCal</th>
                        <th className="text-center">Rating</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={`table-row-${item.id}`}>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td className="text-center">{item.amount}</td>
                          <td className="text-center">{item.units_name}</td>
                          <td className="text-center">{item.semifinished}</td>
                          <td className="text-center">{item.ingredients}</td>
                          <td className="text-center">{item.kcal}</td>
                          <td
                            className={`text-center font-weight-bold${(() => {
                              switch (true) {
                                case item.rate > 0 && item.rate <= 3:
                                  return " text-danger alert-danger";
                                case item.rate > 3 && item.rate <= 4:
                                  return " text-warning alert-warning";
                                case item.rate > 4:
                                  return " text-success alert-success";
                                default:
                                  return "";
                              }
                            })()}`}
                          >
                            {item.rate === 0 ? "-" : item.rate}
                          </td>
                          <td>
                            <Link
                              to={`/meals/${section}/edit/${item.id}`}
                              className={`inline-action-item`}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Link>
                            <Link
                              to={`/meals/${section}/print/${item.id}`}
                              className={`inline-action-item`}
                            >
                              <FontAwesomeIcon icon={faPrint} />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Id</td>
                        <td>Meal</td>
                        <td>Amount</td>
                        <td>Unit</td>
                        <td>Semifinished</td>
                        <td>Ingredients</td>
                        <td>kCal</td>
                        <td>Rating</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </Table>

                  {paginationConfig.totalPages > 1 ? (
                    <Pagination {...paginationConfig} />
                  ) : null}
                </>
              ) : (
                "No entries"
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default List;
