import React from "react";
import { Modal, Button } from "react-bootstrap";

type buttonType = {
  title: string;
  onClick: () => void;
};

type ModalType = {
  title: string;
  message: string;
  Component?: JSX.Element;
  button?: buttonType;
  isOpen: boolean;
  toggle: () => void;
  size?: "sm" | "lg" | "xl" | undefined; // "sm" | "lg" | "xl" | undefined
};

const UcModal = ({
  title,
  message,
  Component,
  button,
  isOpen,
  toggle,
  size,
}: ModalType) => {
  return isOpen ? (
    <Modal show={isOpen} onHide={toggle} centered size={size}>
      <Modal.Header closeButton>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message !== "" ? <p>{message}</p> : ""}
        {Component !== undefined ? Component : ""}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Close
        </Button>
        {button && (
          <Button
            onClick={() => {
              button.onClick();
              toggle();
            }}
            variant="primary"
          >
            {button.title}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default UcModal;
