import React, { useState } from "react";
import { UserContext } from "contexts/UserContext";
import Api from "services/api";
import { useHistory, Link } from "react-router-dom";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { Formik } from "formik";
import DatePickerField from "components/elements/DatePickerField";
import { format } from "date-fns";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const schema = yup.object({
  date_from: yup.string().required(),
  date_to: yup.string().required(),
  week_title: yup.string().required(),
});

type WeeksAvailableType = {
  id: number;
  title: string;
};

type initialDataType = {
  id: number;
  date_from: string;
  date_to: string;
  week_title: string;
  week_id: number;
  weeks_available: Array<WeeksAvailableType>;
};

const Add = () => {
  const section = "calendar";

  const { userData, authFailed } = React.useContext(UserContext);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");

  const initialDataState = {
    id: 0,
    date_from: "",
    date_to: "",
    week_title: "",
    week_id: 0,
    weeks_available: [],
  };

  const [initialData, setInitialData] = useState<initialDataType>(
    initialDataState
  );

  const getData = () => {
    Api.getAll(userData.session_hash, "include/menu-calendar/")
      .then((response: any) => {
        console.log("getData.response", response.data);

        if (response.error) {
          setErrorMessage(response.error);
        } else if (response.authFailed) {
          authFailed();
          localStorage.removeItem("user");
          history.push("/");
        } else {
          setErrorMessage("");
          setInitialData((oldData) => {
            const newData = {
              ...oldData,
              ...response.data,
            };

            console.log("setInitialData", newData);
            return newData;
          });
        }

        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        setErrorMessage("Error: " + e);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h3>
            <Link to={`/menu/${section}/list`}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          {errorMessage !== "" ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : null}
          {isLoading ? (
            <div className="lds-dual-ring"></div>
          ) : (
            <Formik
              validationSchema={schema}
              onSubmit={(formData, { setSubmitting }) => {
                setErrorMessage("");

                /*
                  Prepare dates
                */
                {
                  formData.date_from = format(
                    new Date(formData.date_from),
                    "yyyy-MM-dd"
                  );
                  formData.date_to = format(
                    new Date(formData.date_to),
                    "yyyy-MM-dd"
                  );
                  console.log("onSubmit", formData);
                }

                Api.add(
                  userData.session_hash,
                  `menu-${section}`,
                  formData
                ).then(
                  (response) => {
                    console.log("Add.response", response);

                    if (response.error) {
                      setErrorMessage(response.error);
                    } else if (response.authFailed) {
                      authFailed();
                      localStorage.removeItem("user");
                      history.push("/");
                    } else {
                      history.push(`/menu/${section}/list`);
                    }

                    setSubmitting(false);
                  },
                  (error) => {
                    console.log("Add.error", error);

                    const resMessage =
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      error.message ||
                      error.toString();

                    setErrorMessage(resMessage);
                    setSubmitting(false);
                  }
                );
              }}
              initialValues={initialData}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col className="col-auto">
                      <Form.Group>
                        <Form.Label>Date from</Form.Label>
                        <div>
                          <DatePickerField
                            name="date_from"
                            value={values.date_from}
                            onChange={setFieldValue}
                            isInvalid={!!errors.date_from}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-auto">
                      <Form.Group>
                        <Form.Label>Date to</Form.Label>
                        <div>
                          <DatePickerField
                            name="date_to"
                            value={values.date_to}
                            onChange={setFieldValue}
                            isInvalid={!!errors.date_to}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Week</Form.Label>
                      <Typeahead
                        id="week-selector"
                        multiple={false}
                        allowNew={false}
                        onChange={(selected: any) => {
                          // console.log("selected", selected);
                          const value =
                            selected.length > 0 ? selected[0].title : "";
                          const id = selected.length > 0 ? selected[0].id : 0;
                          console.log("Typeahead.onChange", value, id);
                          setFieldValue("week_title", value);
                          setFieldValue("week_id", id);
                        }}
                        /*onInputChange={(text: any, event: any) => {
                          console.log("Typeahead.onInputChange", text);
                          setFieldValue("week_title", text);
                        }}*/
                        labelKey="title"
                        options={values.weeks_available}
                        inputProps={{
                          className: !!errors.week_title ? "is-invalid" : "",
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={isSubmitting ? "btn--loading" : ""}
                  >
                    {isSubmitting ? (
                      <div className="lds-dual-ring"></div>
                    ) : null}
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Add;
